<template>
    <div>
        <Breadcrumbs main="Banner" title="List of Banner" />
        <div class="col-md-12">
            <div class=" m-b-20">
                <div class="">
                    <div class="media">
                        <div class="media-body text-right" v-if="bannerData.length <= 4">
                            <div class="d-inline-flex" action="#" method="POST" enctype="multipart/form-data" name="myForm">
                                <div class="btn btn-primary mb-0 pt-2" @click="addBannermodal()" data-toggle="modal"
                                    style="display:flex;">
                                    <feather type="plus-square" class="text-top m-r-5"></feather>
                                    Banner
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="row">
                    <div class="col-sm-4" v-for="(banner, index) in bannerData" :key="index">
                        <div class="card">
                            <div class="product-box">
                                <div class="product-img">
                                    <img @error="replaceByDefault" draggable="false" class="img-fluid"
                                        :src="imageLink + banner.image" alt />
                                    <div class="product-hover">
                                        <ul>
                                            <li @click="editBannermodal(banner)">
                                                <button class="btn" type="button" data-toggle="modal"
                                                    data-target="#exampleModalCenter">
                                                    <i class="fa fa-pencil feather-20 ml-1"></i>
                                                </button>
                                            </li>
                                            <li @click="deleteBannermodal(banner.id)">
                                                <button class="btn" type="button" data-toggle="modal"
                                                    data-target="#exampleModalCenter">
                                                    <i class="fa fa-trash feather-20 ml-1"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- add banner modal -->

            <b-modal size="md" centered v-model="addbanner" hide-header hide-footer>
                <button class="close" type="button" v-on:click="cancelmodal()">
                    <span>×</span>
                </button>
                <div class="product-box quickview row card" :key="reloadBanner">
                    <h4>{{ getId == '' ? 'Add' : 'Edit' }} Banner</h4>
                    <hr>

                    <form @submit.stop.prevent="handleSubmit">
                        <label class="col-form-label" for="message-text">Choose Banner:</label>
                        <img class="img-200 mb-2" v-if="getimage" :src="imageLink + getimage" alt />
                        <img class="img-200 mb-2" :src="previewImage" alt />
                        <b-form-file class="mt-2" accept=".jpg, .png" v-model="$v.bannerImage.$model" @change="onUpload"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."></b-form-file>
                        <div class="validation-div mb-2 text-danger"
                            v-if="typeof $v.bannerImage.required !== 'undefined' && $v.bannerImage.required == '' && $v.bannerImage.$error">
                            Image is required.
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" @click="cancelmodal()">Cancel</button>
                    <button class="btn btn-primary" type="button" @click="addEditBanner(getId)">{{ getId == '' ? 'Add'
                        : 'Edit' }} Banner</button>
                </div>
            </b-modal>

            <!-- delete modal -->

            <b-modal v-model="deletemodal" centered class="theme-modal" hide-header hide-footer>
                <h6 class="p-t-20">Are you sure you want to Delete Banner?</h6>

                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" @click="cancel()">Cancel</button>
                    <button class="btn btn-primary" type="button" @click="DeleteBanner(deleteId)">Yes</button>
                </div>
            </b-modal>
        </div>

        <!-- loader -->
        <div class="loader-wrapper" :class="{ 'loderhide': !show }">
            <div class="loader-index">
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
import bannerDefaultImage from '../../constants/onImageError'
import {
    required,
} from "vuelidate/lib/validators";
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    API_URL
} from '../../services/ApiService'
import {
    BannerList,
    addBanner,
    deleteBanner,
    editBanner
} from "@/api/BannerApi"
export default {
    data() {
        return {
            show: false,
            bannerData: [],
            addbanner: false,
            deletemodal: false,
            getId: '',
            deleteId: '',
            previewImage: null,
            getimage: '',
            bannerImage: [],
            reloadBanner:0
        }
    },
    validations() {
        return {
            bannerImage: {
                required
            }
        };
    },
    mixins: [bannerDefaultImage],
    methods: {
        success_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'success',
                icon: 'check',
                duration: 2000
            });
        },
        error_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'error',
                icon: 'times',
                duration: 2000
            });
        },
        getImgUrl(path) {
            return require('../../assets/images/' + path);
        },
        addBannermodal: function (banner) {
            this.reloadBanner++;
            this.getId = ""
            this.getimage = ""
            this.addbanner = true;
        },
        addBannermodalclose: function () {
            this.addbanner = false;
        },
        deleteBannermodal: function (id) {
            this.deletemodal = true;
            this.deleteId = id
        },
        DeleteBanner(id) {
            this.cancel();
            this.show = true
            deleteBanner(id)
                .then((response) => {
                    if (response.status === ResponseStatusEnum.SUCCESS) {
                        this.success_with_icon(response.message)
                    }
                })

                .finally(() => {
                    this.show = false
                    this.getBannerData()
                })
        },

        deleteBannermodalclose: function () {
            this.deletemodal = false;
        },
        editBannermodal: function (data) {
            this.addbanner = true;
            return (this.getId = data.id, this.getimage = data.image)
        },
        getBannerData() {
            this.show = true;
            const data = {
                model: "Banner"
            };
            BannerList(data).then((response) => {
                this.bannerData = response.data.data
            })
                .catch((error) => {
                    this.$toastr.error(error.response.data.message);
                })
                .finally(() => {
                    this.show = false;
                })
        },
        cancel() {
            this.deletemodal = false
        },
        onUpload(event) {
            const file = event.target.files[0]
            this.imagefiles = file
            this.previewImage = URL.createObjectURL(this.imagefiles);
            this.getimage = false

        },
        async addEditBanner(id) {
            this.$v.$touch() 
             if (this.$v.$invalid) {
            } else {
                let data = new FormData();
                data.append('image', this.imagefiles);
                if (this.$v.$invalid) { } else {

                    this.cancelmodal();
                    this.show = true;
                    if (id) {
                        editBanner(id, data).then((response) => {
                            if (response.data.status === ResponseStatusEnum.SUCCESS) {
                                this.success_with_icon(response.data.message);
                                this.previewImage = true
                            } else {
                                this.error_with_icon(response.data.message);
                            }
                        })
                            .catch((error) => {
                                this.error_with_icon(error)
                            })
                            .finally(() => {
                                this.getBannerData()
                            })
                    } else {
                        addBanner(data).then((response) => {
                            this.success_with_icon(response.data.message)
                        })
                            .catch((error) => {
                                this.error_with_icon(error.response.data.message)
                            })
                            .finally(() => {
                                this.getBannerData()
                            })
                    }

                }
            }
        },
        cancelmodal() {
            this.addbanner = false;
            this.previewImage = null;
        }
    },
    mounted() {
        this.getBannerData();
        this.imageLink = API_URL + '/images/'
        document.title = 'Banner';
    }
}
</script>

<style></style>
