import apiService from "../services/ApiService.js";

import {
    VIEW_APPROVAL_LIST,
    DETAILSOF_PRESCRIPTION,
    PRESCRIPTION_APPROVAL
} from "@/api/ApiRoutes";


export const viewApprovalList = async () => {
    const response = await apiService.get(`${VIEW_APPROVAL_LIST}/`);
    return response.data;
};

export const detailsOfPrescription = async (id) => {
    const response = await apiService.get(`${DETAILSOF_PRESCRIPTION}/${id}`);
    return response.data;
};

export const prescriptionApproval = async (data) => {
    const response = await apiService.put(`${PRESCRIPTION_APPROVAL}`, data);
    return response;
};

