import apiService from "../services/ApiService";

import 
{
    ACTIVE_CUSTOMER,
    LATEST_ORDER,
    RECENTLY_JOINED,
    DASHBOARD_CARD
} from "@/api/ApiRoutes"

export const ActiveCustmerGraph = async() => {
    const response = await apiService.get(ACTIVE_CUSTOMER);
    
    return response.data;
};
export const latest_Order = async() => {
    const response = await apiService.get(LATEST_ORDER);
    return response.data;
};
export const recently_Joined = async() => {
    const response = await apiService.get(RECENTLY_JOINED);
    return response.data;
};

export const Dashboard_card = async() => {
    const response = await apiService.get(DASHBOARD_CARD);
    return response?.data;
};



