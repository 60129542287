import apiService from "../services/ApiService.js";

import {
  GET_BANNER,
  ADD_BANNER,
  EDIT_BANNER,
  DELETE_BANNER,
} from "@/api/ApiRoutes";

export const BannerList = async (data) => {
  const response = await apiService.post(GET_BANNER, data);
  return response;
};

export const addBanner = async (formData) => {
  let response = await apiService.post(ADD_BANNER, formData, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
  return response;
};

export const editBanner = async (id, data) => {
  const response = await apiService.put(`${EDIT_BANNER}/${id}`, data
  , {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  }
  );
  return response;
};

export const deleteBanner = async (id) => {
  const response = await apiService.delete(`${DELETE_BANNER}/${id}`);
  return response.data;
};
