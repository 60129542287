<template>
<div>
    <Breadcrumbs main="Invoice" title="order" />
    <!-- Container-fluid starts-->
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card" >
                    <div class="card-body">
                        <div class="invoice">
                            <div id="printableArea">
                                <div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="media">
                                                <div class="media-left">
                                                    <img class="media-object img-60" src="../../assets/image/logo/small-logo.jpg" alt />
                                                </div>
                                                <div class="media-body m-l-20">
                                                    <h4 class="media-heading">Shiv Infotech</h4>
                                                   
                                                </div>
                                            </div>
                                            <!-- End Info-->
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="text-md-right">
                                                <h3>
                                                    Invoice
                                                    <span class="digits counter">#{{invoicedata[0]?.user_id}}</span>
                                                </h3>
                                                <p>

                                                    <br />Payment Due:
                                                    <span class="digits">{{invoicedata[0]?.created_at}}</span>
                                                </p>
                                            </div>
                                            <!-- End Title-->
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <!-- End InvoiceTop-->
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="media">
                                            <div class="media-left">
                                                <!-- <img class="media-object rounded-circle img-60" src="../../assets/images/user/1.jpg" alt /> -->
                                            </div>
                                            <div class="media-body m-l-20">
                                                <!-- <h4 class="media-heading">{{invoicedata[0].first_name}} {{invoicedata[0].last_name}}</h4> -->
                                                <!-- <p>
                                                    JohanDeo@gmail.com
                                                    <br />
                                                    <span class="digits">555-555-5555</span>
                                                </p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="text-md-right" id="project">
                                            <h6>Address</h6>
                                            <p class="m-5">{{invoiceaddress?.address_line_1}}</p>
                                            <p class="m-5">{{invoiceaddress?.address_line_2}}</p>
                                            <p class="m-5">{{invoiceaddress?.city_id}}</p>
                                            <p class="m-5">{{invoiceaddress?.state_id}}</p>
                                            <p class="m-5">{{invoiceaddress?.country_id}}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Invoice Mid-->
                                <div>
                                    <div class="table-responsive invoice-table" id="table">
                                        <table class="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <td class="item">
                                                        <h6 class="p-2 mb-0">Name</h6>
                                                    </td>
                                                    <td class="Hours">
                                                        <h6 class="p-2 mb-0">Description</h6>
                                                    </td>
                                                    <td class="Rate">
                                                        <h6 class="p-2 mb-0">Price</h6>
                                                    </td>
                                                    <td class="subtotal">
                                                        <h6 class="p-2 mb-0">Quantity</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label>{{invoicedata[0]?.name}}</label>

                                                    </td>
                                                    <td>
                                                        <p class="itemtext digits">{{invoicedata[0]?.description}}</p>
                                                    </td>
                                                    <td>
                                                        <p class="itemtext digits">RS {{invoicedata[0]?.price}}</p>
                                                    </td>
                                                    <td>
                                                        <p class="itemtext digits">{{invoicedata[0]?.quantity}}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="Rate">
                                                        <h6 class="mb-0 p-2">Total</h6>
                                                    </td>
                                                    <td class="payment digits">
                                                        <h6 class="mb-0 p-2">RS {{invoicedata[0]?.subTotal}}</h6>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- End Table-->
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div>
                                                <p class="legal">
                                                    <strong>Thank you for your business!</strong>Payment is expected within 31 days; please process this invoice within that time. There will be a 5% interest charge per month on late invoices.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <form class="text-right">
                                                <img src="../../assets/images/other-images/paypal.png" class="mt-2" alt="PayPal - The safer, easier way to pay online!" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- End InvoiceBot-->
                            </div>
                            <div class="col-sm-12 text-center mt-3">
                                <button class="btn btn btn-primary mr-2" type="button" @click="printDiv('printableArea')">Print</button>
                                <button class="btn btn-secondary" type="button" @click="cancel()">Cancel</button>
                            </div>
                            <!-- End Invoice-->
                            <!-- End Invoice Holder-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
    <!-- loader -->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
        <div class="loader-index">
            <span></span>
        </div>
    </div>
</div>
</template>

<script>
import {
    dateFormat
} from '../../constants/comman'
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    invoice_Data
} from "@/api/OrderApi"
export default {
    data() {
        return {
            show: false,
            invoicedata: [],
            invoiceaddress: [],
            created_at: ''
        }
    },
    created() {
        this.order_id = this.$route.query.order_id
    },
    methods: {
        printWindow() {
            window.print();
        },
        getInvoiceData() {
            this.show = true
            let id = this.order_id
            invoice_Data(id).then((response) => {
                    if (response.status == ResponseStatusEnum.SUCCESS) {
                        this.invoicedata = response.data.order
                        this.invoiceaddress = response.data.address
                        this.invoicedata.forEach((item) => {
                            item.created_at = dateFormat(item.created_at)
                        })
                    } else {
                        this.$toastr.error(response.data.message);
                    }
                })
                .catch((error) => {
                    this.$toastr.error(error.response.data.message);
                })
                .finally(() => {
                    this.show = false;
                })
        },
        cancel() {
            this.$router.push('/admin/order')
        },
        printDiv(divName) {
            var printContents = document.getElementById(divName).innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;
        }
    },
    mounted() {
       
        this.getInvoiceData();
        document.title = 'Invoice';
    }
};
</script>

<style>

</style>
