import Vue from 'vue';
import Router from 'vue-router';
import Body from '../components/body';
import Auth from '../auth/auth';
/* Auth */
import login from '../auth/login';
import callback from '../pages/callback';

// category 
import Category from '../pages/category/categoryList'

/* Dashboards */
import Ecommerce from '../pages/dashboard/ecommerce';
import addEditProduct from '../pages/product/addEditProduct'
import productList from '../pages/product/productList'
import bannerList from '../pages/banner/bannerList'
import orderList from '../pages/order/orderList'
import viewInvoice from '../pages/order/viewInvoice'
import prescriptionList from '../pages/prescription/prescriptionList'
import report from '../pages/report/generateReport'

// component

Vue.use(Router);

const routes = [
  { path: '', redirect: { name: 'login' } },
  {
    path: '/admin',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'ecommerce',
        component: Ecommerce,
        meta: {
          title: 'Ecommerce Dashboard | Cuba - Premium Admin Template',
        }
      },
      {
        path: 'category',
        name: 'category',
        component: Category,

      },
      {
        path: 'product',
        name: 'productList',
        component: productList,
      },
      {
        path: 'ecommerce',
        name: 'ecommerce',
        component: Ecommerce,
        meta: {
          title: 'Ecommerce Dashboard | Cuba - Premium Admin Template',
        }
      },
      {
        path: 'addProduct',
        name: 'addProduct',
        component: addEditProduct,
      },
      {
        path: 'editproduct',
        name: 'editproduct',
        component: addEditProduct,
        props: { default: 'Add Product', title: 'Edit Product' },
      },
      {
        path: 'banner',
        name: 'bannerList',
        component: bannerList,
      },
      {
        path: 'order',
        name: 'order',
        component: orderList,
      },
      {
        path: 'invoice',
        name: 'invoice',
        component: viewInvoice,
        props: true
      },
      {
        path: 'prescription',
        name: 'prescription',
        component: prescriptionList,
      },
      {
        path: 'report',
        name: 'report',
        component: report,
      }
    ]
  },

  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'login',
        component: login,
        meta: {
          title: ' login | Cuba - Premium Admin Template',
        }
      }
    ]
  },


];

const router = new Router({
  routes,
  base: process.env.BASE_URL,
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});


router.beforeEach((to, from, next) => {
  let isAuthenticated = localStorage.getItem('token')
  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else next()
})

export default router;
