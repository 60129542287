import apiService from "../services/ApiService.js";

import {
  CATEGORY_LIST,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "@/api/ApiRoutes";

export const CategoryList = async (id, data) => {
  const response = await apiService.post(`${CATEGORY_LIST}`, id, data);
  return response.data;
};

export const addCategory = async (formData) => {
  let response = await apiService.post(ADD_CATEGORY, formData, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
  return response;
};

export const Updatecategory = async (id, data) => {
  const response = await apiService.put(`${UPDATE_CATEGORY}/${id}`, data);
  return response.data;
};
export const Deletecategory = async (id, data) => {
  const response = await apiService.put(`${DELETE_CATEGORY}/${id}`, data);
  return response.data;
};
