<template>
<div class="ovr-y">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-7 b-center bg-size" :style="{ backgroundImage: 'url('+ require('../assets/image/logo/2.jpg') +')' }" style="background-size: cover; background-position: center center; display: block;">
                <img class="bg-img-cover bg-center" src="../assets/image/logo/2.jpg" alt="looginpage" style="display: none;" />
            </div>
            <div class="col-xl-5 p-0">
                <div class="login-card">
                    <div>
                        <div>
                            <a class="logo text-center">
                                <img class="img-fluid for-light loginLogo" src="../assets/image/shiv.png" alt="looginpage" />
                                <h5>Shiv Infotech</h5>
                                <img class="img-fluid for-dark loginLogo" src="../assets/image/shiv.png" alt="looginpage" />
                            </a>
                        </div>
                        <div class="login-main login-form-card login-res">
                            <form class="theme-form">
                                <h4>Sign in to account</h4>
                                <p>Enter your email & password to login</p>
                                <div class="form-group">
                                    <label class="col-form-label">Email Address</label>
                                    <input v-on:keyup.enter="submit" class="form-control" type="email" required="" placeholder="Enter your Email" v-model="$v.email.$model"   />
                                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.email.required !=='undefined' && $v.email.required == '' && $v.email.$error">
                                        Email id is required.
                                    </div>
                                    <div class="validation-div mb-2 text-danger" v-if="!$v.email.email">
                                        Email id should be in correct format.
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Password</label>
                                    <input onKeyPress="if(this.value.length==30) return false;" v-on:keyup.enter="submit" v-model="$v.password.$model" autocomplete="" class="form-control" :type="type" name="login[password]" required="" placeholder="Enter your Password" />
                                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.password.required !=='undefined' && $v.password.required == '' && $v.password.$error">
                                        Password is required.
                                    </div>
                                    <div class="show-hide" @click="showPassword">
                                        <span class="show"></span>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <button class="btn btn-primary btn-block m-t-20" type="button" @click.capture="submit" >
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- loader code -->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
        <div class="loader-index">
            <span></span>
        </div>
    </div>

</div>
</template>

<script>
import jwt_decode from "jwt-decode"
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    login
} from "@/api/AuthApi";
import {
    required,
    email
} from "vuelidate/lib/validators";
export default {
    name: 'loginPage',
    data() {
        return {
            show: false,
            type: 'password',
            email: '',
            password: '',
            submitted: false,
        };
    },
    computed: {},
    created() {},
    validations() {
        return {
            email: {
                required,
                email
            },
            password: {
                required,
            }
        };
    },
    methods: {
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
            } else {
                this.signUp();
            }
        },

        showPassword: function () {
            if (this.type === 'password') {
                this.type = 'text';
            } else {
                this.type = 'password';
            }
        },

        signUp() {
            this.show = true;

            const data = {
                email_id: this.email,
                password: this.password,
                role: "Admin"
            };
            login(data)
                .then((response) => {
                    if (response.data.status == ResponseStatusEnum.SUCCESS) {

                        let token = response.data.data.token
                        localStorage.setItem("token", response.data.data.token)
                        const decode = jwt_decode(token).role

                        localStorage.setItem("role", decode)
                        localStorage.setItem("user_id", response.data.data.id)

                        this.$router.push("/admin/dashboard");
                        this.$toasted.show(response.data.message, {
                            theme: 'outline',
                            position: 'top-right',
                            type: 'success',
                            icon: 'check',
                            duration: 2000
                        });
                    } else {
                        this.$toasted.show(response.data.message, {
                            theme: 'outline',
                            position: 'top-right',
                            type: 'error',
                            icon: 'check',
                            duration: 2000
                        });
                    }
                })
                .catch((error) => {
                    this.$toasted.show(error.response.data.message, {
                        theme: 'outline',
                        position: 'top-right',
                        type: 'error',
                        icon: 'check',
                        duration: 2000
                    });
                })
                .finally(() => {
                    this.show = false;
                })
        },
        preventBack() {
            window.history.forward();
        }
    },
    mounted() {
        this.preventBack();
    }
}
</script>


<style scoped>
.ovr-y{
    overflow-y: hidden !important;
}
.toasted-container.top-right > .toasted {
    top: -18px !important;
}
</style>
