<template>
  <router-link to="/admin/dashboard">
    <img
      class="img-fluid for-light sidebarlogo"
      src="../../assets/image/shiv.png"
      alt=""
    /><img
      class="img-fluid for-dark sidebarlogo"
      src="../../assets/image/shiv.png"
      alt=""
    />
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>
