<template>
    <div>
        <Breadcrumbs title="Category" />
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="media">
                        <div class="form-inline">
                            <div class="form-group mb-0">
                                <h5 class="card-title">List of Category</h5>
                            </div>
                        </div>
                        <div class="media-body text-right">
                            <div class="d-inline-flex">
                                <div class="btn btn-primary mb-0 pt-2 d-flex" @click="AddCategory()">
                                    <feather type="plus-square" class="text-top m-r-5"></feather>
                                    Category
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body m-2">
                    <div v-if="isNotFound">

                        <div v-if="CategoryList.length == 0" class="m-t-20 m-b-20">
                            <h5 class="text-muted text-center">No Data Found!</h5>
                        </div>
                    
                    <div v-else class="datatable-vue m-0">
                        <div class="row filter-smart">
                            <div class="col-sm-3">
                                <input class="form-control" v-model="filters.srno.value" placeholder="Sr.no" />
                            </div>
                            <div class="col-sm-3">
                                <input class="form-control" v-model="filters.name.value" placeholder="Name" />
                            </div>
                            <div class="col-sm-3">
                                <input class="form-control" v-model="filters.description.value" placeholder="Description" />
                            </div>
                        </div>
                        <div class="table-responsive vue-smart">

                            <v-table :data="CategoryList" class="table" :currentPage.sync="filter.currentPage"
                                :pageSize="10" @totalPagesChanged="filter.totalPages = $event" :filters="filters">

                                <thead slot="head">
                                    <v-th sortKey="srno" class="sortable-column">Sr.no</v-th>
                                    <v-th sortKey="name" class="sortable-column">Name</v-th>
                                    <v-th sortKey="description" class="sortable-column">Description</v-th>
                                    <v-th :hideSortIcons="true" class="sortable-not-allowed">Action</v-th>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">
                                    <tr v-if="displayData.length === 0">
                                        <td colspan="12" class="text-center">Sorry!! Result Not Found</td>
                                    </tr>
                                    <tr v-for="(row, index) in displayData" :key="index">
                                        <td>{{ row.srno }}</td>
                                        <td>
                                            <div class="desc_title_width">
                                                {{ row.name }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="desc_width">{{ row.description }}
                                            </div>
                                        </td>

                                        <td>
                                            <b-button :id="`editBanner-${index}`" class="btn-xs " variant="light"
                                                @click="EditCategory(row)">
                                                <i class="fa fa-pencil feather-20 "></i>
                                            </b-button>
                                            <b-tooltip :target="`editBanner-${index}`" triggers="hover"
                                                placement="right">Edit Category</b-tooltip>

                                            <b-button :id="`deleteBanner-${index}`" class="btn-xs ml-2" variant="danger"
                                                @click="deletemodalshow(row)" data-toggle="modal">
                                                <i class="fa fa-trash feather-20"></i>
                                            </b-button>
                                            <b-tooltip :target="`deleteBanner-${index}`" triggers="hover"
                                                placement="right">Delete Category</b-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
    
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-md-7 col-xs-6"></div>
                            <div class="col-sm-8 col-md-5 col-xs-6">
                                <smart-pagination :maxPageLinks="5" :currentPage.sync="filter.currentPage"
                                    :totalPages="filter.totalPages" />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <!-- Add-Edit category Modal -->

        <b-modal ref="add-edit-category-modal" centered title="Add Category" class="theme-modal" hide-footer hide-header>
            <button class="close" type="button" v-on:click="cancel()">
                    <span>×</span>
                </button>
            <!-- <div class="modal-header"> -->
                <h4>{{ getId == '' ? 'Add' : 'Edit' }} Category </h4>
                <hr>
            <!-- </div> -->
            <label class="col-form-label" for="recipient-name">Name:</label>
            <b-form-input v-model="$v.categoryName.$model" placeholder="Enter the Category Name"></b-form-input>
            <div class="validation-div mb-2 text-danger"
                v-if="typeof $v.categoryName.required !== 'undefined' && $v.categoryName.required == '' && $v.categoryName.$error">
                Category name is required.
            </div>

            <label class="col-form-label" for="message-text">Description:</label>
            <b-form-textarea maxlength="130" id="textarea" placeholder="Enter Category Description"
                v-model="categoryDescription" rows="2"></b-form-textarea>
            <div class="validation-div mb-2 text-danger" v-if="typeof $v.categoryDescription.required !=='undefined' && $v.categoryDescription.required == '' && $v.categoryDescription.$error">
                Category description is required.
            </div>

            <label class="col-form-label" for="message-text">Image:</label>
            <div>

                <img class="img-200 m-45"  v-if="getId != ''" @error="replaceByDefault" :src="imageLink + editimage" :alt="imageLink + editimage" />
                <img class="img-200 m-45"  :src="previewImage" alt="" style="margin-left: 50px;"/>
            </div>
            <b-form-file accept=".jpg, .png" @change="onUpload" v-model="imageUpload"
                placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>

            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" @click="cancel()">Cancel</button>
                <button class="btn btn-primary" type="button" @click="addEditcategory(getId)">{{ getId == '' ? 'Add'
                    : 'Edit' }} Category</button>
            </div>
        </b-modal>
        <!-- delete modal -->

        <b-modal v-model="deletemodal" centered class="theme-modal" hide-header hide-footer>
            <h6 class="p-t-20">Are you sure you want to Delete <span style="color:#7366FF">{{ deletename }} </span> Category?
            </h6>

            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" @click="canceldeletemodal()">Cancel</button>
                <button class="btn btn-primary" type="button" @click="DeleteCategory(deleteId)">Yes</button>
            </div>
        </b-modal>

        <!-- loader code -->
        <div class="loader-wrapper" :class="{ 'loderhide': !show }">
            <div class="loader-index">
                <span></span>
            </div>
        </div>

    </div>
</template>
    
<script>
import onImageError from "@/constants/onImageError";
import {
    required,
} from "vuelidate/lib/validators";
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    CategoryList,
    addCategory,
    Updatecategory,
    Deletecategory
} from "@/api/CategoryApi";
import {
    API_URL
} from "../../services/ApiService";
export default {
    mixins: [onImageError],
    components: {

    },
    data() {
        return {
            categoryName: "",
            categoryDescription: "",
            imageUpload: null,
            previewImage: undefined,
            CategoryList: [],
            show: false,
            deletemodal: false,
            filter: {
                currentPage: 1,
                totalPages: 0,
            },
            filters: {
                srno: {
                    value: '',
                    keys: ['srno']
                },
                name: {
                    value: '',
                    keys: ['name']
                },
                description: {
                    value: '',
                    keys: ['description']
                }
            },
            getId: '',
            editdata: '',
            deleteId: '',
            deletename: '',
            editimage: '',
            imageNew: '',
            isNotFound:false
        }
    },
    validations() {
        return {
            categoryName: {
                required
            },
            categoryDescription:{
                required
            }
        };
    },
    methods: {
        onUpload(event) {
            let imageNew = event.target.files[0];
            this.imageNew = imageNew;
            this.previewImage = URL.createObjectURL(imageNew);
        },
        AddCategory: function () {
            this.getId = ""

            this.$refs['add-edit-category-modal'].show()
        },
        EditCategory: function (data) {
            this.$refs['add-edit-category-modal'].show()
            this.categoryName = data.name
            this.categoryDescription = data.description
            this.editimage = data.image
            return (this.getId = data.id, this.editdata = data)
        },
        success_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'success',
                icon: 'check',
                duration: 2000
            });
        },
        error_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'error',
                icon: 'times',
                duration: 2000
            });
        },
        cancel() {
            this.categoryName = ''
            this.categoryDescription = ''
            this.previewImage = ''
            this.$refs['add-edit-category-modal'].hide()
        },
        canceldeletemodal() {
            this.deletemodal = false
        },
        deletemodalshow: function (data) {
            this.deletemodal = true;
            this.deleteId = data.id
            this.deletename = data.name
        },
        DeleteCategory(id) {
            this.show = true;
            const data = {
                is_archived: true,
            };
            this.deletemodal = false
            Deletecategory(id, data)
                .then((response) => {
                    if (response.status === ResponseStatusEnum.SUCCESS) {
                        this.success_with_icon(response.message)
                        this.getCategoryData();
                    } else {
                        this.error_with_icon(response.message)
                    }
                })
                .catch((error) => {
                    this.error_with_icon(error.response.data.message)
                })
        },
        getCategoryData() {
            this.show = true;
            const data = {
                model: "Category",
                limit: 500,
                condition: {
                    is_archived: false,
                },
                sortField: [{
                    id: "DESC",
                },],
            };
            CategoryList(data)
                .then((response) => {
                    if (response.status == "success") {
                        this.CategoryList = response.data;
                        this.CategoryList = this.CategoryList.map((item, index) => ({
                            ...item,
                            srno: index + 1,
                        }));
                    } else {
                        this.error_with_icon(response.data.message);
                    }
                })
                .catch((error) => {
                    this.error_with_icon(error.response.data.message);
                })
                .finally(() => {
                    this.show = false;
                    this.isNotFound=true;
                });
        },
        async addEditcategory(id) {
            this.$v.$touch()
            if (this.$v.$invalid) { } else {

                this.$refs['add-edit-category-modal'].hide()
                this.show = true;
                let formData = new FormData();
                formData.append("name", this.categoryName);
                formData.append("order", parseInt("1"));
                formData.append("description", this.categoryDescription);
                formData.append("image", this.imageNew);
                if (id) {
                    Updatecategory(id, formData).then((response) => {
                        this.cancel();
                        if (response.status == ResponseStatusEnum.SUCCESS) {
                            this.success_with_icon(response.message)
                            this.getCategoryData();
                        } else {
                            this.error_with_icon(response.message)
                        }
                    })
                } else {
                    addCategory(formData)
                        .then((response) => {
                            this.cancel();
                            this.show = true;
                            if (response.data.status == ResponseStatusEnum.SUCCESS) {
                                this.success_with_icon(response.data.message)
                                this.getCategoryData();

                            } else {
                                this.error_with_icon(response.message)
                            }
                        })
                        .catch((error) => {
                            this.error_with_icon(error.response.data.message)
                        })
                }
            }
        },
        
    },
    async mounted() {
        document.title = 'Category';
        this.imageLink = API_URL + "/images/";
        this.getCategoryData();
    }
}
</script>
    
<style>
::-webkit-input-placeholder {
    font-size: 14px;
}

.parent {
    width: 30rem;
}

.parent-name {
    width: 10rem;
}

.block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 43px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card .card-body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: transparent;
}

.card .card-header {
    background-color: #fff;
    padding: 14px !important;
    border-bottom: 1px solid #ecf3fa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
}

.filter-smart {
    margin-bottom: 20px !important;
}

.sortable-column{
    cursor: pointer;
}
.sortable-not-allowed{
    cursor: not-allowed;
}
.desc_width {
    white-space: nowrap;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.desc_title_width {
    white-space: nowrap;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
    