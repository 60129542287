import apiService from "../services/ApiService.js";

import { CUSTOMER_ACTIVITY_REPORT, CUSTOMER_ORDER_REPORT, CUSTOMER_TRANSACTION_REPORT, PURCHASED_PRODUCT_REPORT, SALES_REPORT, VENDOR_REPORT } from "@/api/ApiRoutes";

export const purchasedProductReport = async(data , reportData) => {
  const response = await apiService.post(PURCHASED_PRODUCT_REPORT, data , reportData);
  return response.data;
};

export const vendorReport = async(data) => {
  const response = await apiService.post(VENDOR_REPORT, data);
  return response.data;
};

export const customerActivityReport = async(data) => {
  const response = await apiService.post(CUSTOMER_ACTIVITY_REPORT, data);
  return response.data;
};

export const customerOrderReport = async(data) => {
  const response = await apiService.post(CUSTOMER_ORDER_REPORT, data);
  return response.data;
};

export const customerTransactionReport = async(data) => {
  const response = await apiService.post(CUSTOMER_TRANSACTION_REPORT, data);
  return response.data;
};

export const salesReport = async(data) => {
  const response = await apiService.post(SALES_REPORT, data);
  return response.data;
};