<template>
<div>
    <Breadcrumbs main="Product" title="List of Product" />
    <div :class="filtered ? 'container-fluid product-wrapper sidebaron' : 'container-fluid product-wrapper'">
        <div class="product-grid">
            <div class="feature-products">
                <div class="row mb-2">
                    <div class="col-md-6 products-total">
                        <!-- future reference -->
                        <!-- <div class="square-product-setting d-inline-block">
                            <a class="icon-grid grid-layout-view" @click="gridView()">
                                <feather type="grid"></feather>
                            </a>
                        </div>
                        <div class="square-product-setting d-inline-block">
                            <a class="icon-grid m-0 list-layout-view" @click="listView()">
                                <feather type="list"></feather>
                            </a>
                        </div>
                        <div class="grid-options d-inline-block">
                            <ul>
                                <li @click="grid2()" :style="forfirefox">
                                    <a href="#" class="product-2-layout-view">
                                        <span class="line-grid line-grid-1 bg-primary"></span>
                                        <span class="line-grid line-grid-2 bg-primary"></span>
                                    </a>
                                </li>
                                <li @click="grid3()">
                                    <a href="#" class="product-3-layout-view">
                                        <span class="line-grid line-grid-3 bg-primary"></span>
                                        <span class="line-grid line-grid-4 bg-primary"></span>
                                        <span class="line-grid line-grid-5 bg-primary"></span>
                                    </a>
                                </li>
                                <li @click="grid4()">
                                    <a href="#" class="product-4-layout-view">
                                        <span class="line-grid line-grid-6 bg-primary"></span>
                                        <span class="line-grid line-grid-7 bg-primary"></span>
                                        <span class="line-grid line-grid-8 bg-primary"></span>
                                        <span class="line-grid line-grid-9 bg-primary"></span>
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                    
                        <div class="form-group">
                            <label class="control-label">Product Name</label>
                            <input class="form-control" v-model="product_Name" type="text" placeholder="Enter Product Name" required="required">
                        </div>
                        <div class="form-group ml-2">
                            <label class="control-label">Prescription</label>
                            <select class="form-control btn-square" v-model="prescription_filter">
                                <option value="" selected disabled>
                                    {{"Select Prescription"}}
                                </option>
                                <option :value="true">
                                    Yes
                                </option>
                                <option :value="false">
                                    No
                                </option>
                            </select>
                        </div>
                        
                        <div class=" btn btn-secondary mb-0 pt-2 mt-2 ml-2" style="display:flex;" @click="filterData()">
                            <feather type="search" class="text-top m-r-5"></feather>
                            Filter
                        </div>

                  
                    </div>
                    <div class="col-md-6">
                        <div class="pull-right text-right pt-4">
                            <router-link :to="'/admin/addProduct'">
                                <div class=" btn btn-primary mb-0 pt-2 " style="display:flex;">
                                    <feather type="plus-square" class="text-top m-r-5"></feather>
                                    Product
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Future reference -->
            <!-- <div class="feature-products">
                <div class="row mb-2">
                    <div class="col-md-7 products-total">
                        <div class="form-group">
                            <label class="control-label">Product Name</label>
                            <input class="form-control" v-model="product_Name" type="text" placeholder="Enter Product Name" required="required">
                        </div>
                        <div class="form-group ml-2">
                            <label class="control-label">Prescription</label>
                            <select class="form-control btn-square" v-model="prescription_filter">
                                <option value="" selected disabled>
                                    {{"Select Prescription"}}
                                </option>
                                <option :value="true">
                                    Yes
                                </option>
                                <option :value="false">
                                    No
                                </option>
                            </select>
                        </div>
                        <div class=" btn btn-secondary mb-0 pt-2 mt-2 ml-2" style="display:flex;" @click="filterData()">
                            <feather type="search" class="text-top m-r-5"></feather>
                            Filter
                        </div>

                    </div>
                </div>
            </div> -->
            <div v-if="isNotFound">

                <div v-if="ProductList.length  == 0" class="m-t-20 m-b-20">
                    <h5 class="text-muted text-center">No Data Found!</h5>
                </div>
                <div v-else class="product-wrapper-grid m-t-20" :class="listViewEnable?'list-view':''">
                    <div class="row">
                        <div :class="[col2 ? 'col-md-6': col3 ? 'col-lg-4 col-md-6' : col4 ? 'col-xl-3 col-md-6' : 
                          col6 ? 'col-xl-2 col-lg-4 col-md-6' : list ? 'col-xl-12' : 'col-xl-3 col-md-6']" v-for="(product,index) in ProductList" :key="index">
                            <div class="card">
                                <div class="product-box">
                                    <div class="product-img">
                                        <img @error="replaceByDefault" class="img-fluid fullwidthImage" :src="imageLink + product?.image || onImageError" alt />
                                        <div class="product-hover">
                                            <ul>
                                                <router-link :to="{ name: 'editproduct', params: { product_id: product.product_id } , query:{ product_id: product.product_id} }">
                                                    <li>
                                                        <button class="btn" type="button">
                                                            <i class="fa fa-pencil feather-20 ml-1"></i>
                                                        </button>
                                                    </li>
                                                </router-link>
                                                <li @click="quickView(product)">
                                                    <button class="btn" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                                                        <i class="fa fa-regular fa-eye feather-20 ml-1"></i>
                                                    </button>
                                                </li>
                                                <li @click="deletemodalshow(product)">
                                                    <button class="btn" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                                                        <i class="fa fa-trash feather-20 ml-1"></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="product-details">
                                        <router-link :to="'/ecommerce/details/'+product.sku">
                                            <h4 class="product_name_width">{{product.name}}</h4>
                                        </router-link>
                                        <p class="product_descreption_width">{{product.description}}</p>
                                        <div class="product-price">
                                            MMK.{{product.price }}

                                        </div>
                                        <p v-if="product.quantity==0" class="product_quantity_width">Available Stock:{{product.quantity}}</p>
                                        <p v-else class="product_descreption_width">Available Stock:{{product.quantity}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal here  -->
        <b-modal size="lg" centered v-model="modalShow" hide-footer hide-header>
            <button class="close" type="button" v-on:click="quickViewClose(modalShow)">
                <span>×</span>
            </button>
            <div class="product-box quickview row">
                <div class="product-img col-lg-6">
                    <img @error="replaceByDefault" class="img-fluid" :src="imageLink + quickViewProduct?.image || onImageError" alt="">
                </div>
                <div class="product-details col-lg-6 text-left">
                    <h4>{{quickViewProduct.name || 'NA'}}</h4>
                    <div class="product-price">
                        Rs.{{quickViewProduct.price }}
                    </div>
                    <div class="product-view">
                        <h6 class="f-w-600">Product Description</h6>
                        <p class="mb-0">
                            {{quickViewProduct.description || 'NA'}}
                        </p>
                    </div>

                    <div class="product-view">
                        <h6 class="f-w-300">Meta Tag Title : <span class="mb-0 f-w-100">
                                {{quickViewProduct.metaTagTitle || 'NA'}}
                            </span></h6>

                        <h6 class="f-w-300">Meta Tag Description : <span class="mb-0 f-w-100">
                                {{quickViewProduct.metaTagDescription || 'NA'}}
                            </span></h6>

                        <h6 class="f-w-300">Meta Tag Keywords : <span class="mb-0 f-w-100">
                                {{quickViewProduct.metaTagKeywords || 'NA'}}
                            </span></h6>

                        <h6 class="f-w-300">Composition : <span class="mb-0 f-w-100">
                                {{quickViewProduct.composition || 'NA'}}
                            </span></h6>

                        <h6 class="f-w-300">Presentation : <span class="mb-0 f-w-100">
                                {{quickViewProduct.presentation || 'NA'}}
                            </span></h6>

                        <h6 class="f-w-300">Storage : <span class="mb-0 f-w-100">
                                {{quickViewProduct.storage || 'NA'}}
                            </span></h6>

                        <h6 class="f-w-300">Indication : <span class="mb-0 f-w-100">
                                {{quickViewProduct.indication || 'NA'}}
                            </span></h6>
                        <h6 class="f-w-300">Dose : <span class="mb-0 f-w-100">
                                {{quickViewProduct.dose || 'NA'}}
                            </span></h6>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- delete modal -->

        <b-modal v-model="deletemodal" centered class="theme-modal" hide-header hide-footer>
            <h6 class="p-t-20">Are you sure you want to Delete <span style="color:#7366FF">{{deletename}} </span> Product?</h6>

            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" @click="cancel()">Cancel</button>
                <button class="btn btn-primary" type="button" @click="DeleteProduct(deleteId)">Yes</button>
            </div>
        </b-modal>

    </div>
    <!-- loader -->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
        <div class="loader-index">
            <span></span>
        </div>
    </div>

</div>
</template>

<script>
import onImageError from "@/constants/onImageError";
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    API_URL
} from '../../services/ApiService'
import {
    productList,
    Deleteproduct,
    searchProductList
} from "@/api/ProductApi"
export default {
    data() {
        return {
            show: false,
            quickViewProduct: [],
            list: false,
            col2: false,
            col3: false,
            col4: true,
            col6: false,
            listViewEnable: false,
            filtered: false,
            modalShow: false,
            deletemodal: false,
            ProductList: [],
            deleteId: '',
            deletename: '',
            imageLink: '',
            prescription_filter: '',
            product_Name: '',
            isNotFound: false
        }
    },
    mixins: [onImageError],
    methods: {
        grid2() {
            this.col2 = true;
            this.col3 = false;
            this.col4 = false;
            this.col6 = false;
            this.listViewEnable = false;
        },
        grid3() {
            this.col2 = false;
            this.col3 = true;
            this.col4 = false;
            this.col6 = false;
            this.listViewEnable = false;
        },
        grid4() {
            this.col2 = false;
            this.col3 = false;
            this.col4 = true;
            this.col6 = false;
            this.listViewEnable = false;
        },
        grid6() {
            this.col2 = false;
            this.col3 = false;
            this.col4 = false;
            this.col6 = true;
            this.listViewEnable = false;
        },
        listView() {
            this.listViewEnable = true;
            this.list = true;
            this.col2 = false;
            this.col3 = false;
            this.col4 = false;
            this.col6 = false;
        },
        gridView() {
            this.listViewEnable = false;
            this.col4 = true;
        },
        quickView: function (product) {
            this.modalShow = true;
            return (this.quickViewProduct = product);
        },
        quickViewClose: function () {
            this.modalShow = false;
        },
        deletemodalshow: function (data) {
            this.deletemodal = true;
            this.deleteId = data.product_id
            this.deletename = data.name
        },
        getProductdata() {
            this.show = true;
            const data = {
                sort: {
                    product_id: "DESC"
                }
            }
            // this.show = true
            productList(data).then((response) => {
                    if (response.data.status === ResponseStatusEnum.SUCCESS) {
                        this.ProductList = response.data.data.item
                    } else {
                        this.$toastr.error(response.data.message);
                    }
                })

                .catch(error => {
                    this.$toastr.error(error.response.data.message);
                })
                .finally(() => {
                    this.show = false;
                    this.isNotFound = true;
                })
        },
        cancel() {
            this.deletemodal = false
        },
        filterData() {
            this.show = true;
            const data = {
                status: this.prescription_filter,
                productName: this.product_Name
            };
            searchProductList(data).then((response) => {
                    if (response.data.status === ResponseStatusEnum.SUCCESS) {
                        this.ProductList = response.data.data
                    } else {
                        this.$toastr.error(response.data.message);
                    }
                })

                .catch(error => {
                    this.$toastr.error(error.response.data.message);
                })
                .finally(() => {
                    this.show = false;
                })
        },
        DeleteProduct(id) {
            this.show = true;
            const data = {
                "is_archived": true
            };
            this.cancel();
            Deleteproduct(id, data)
                .then((response) => {
                    if (response.status === ResponseStatusEnum.SUCCESS) {
                        this.$toasted.show(response.message, {
                            theme: 'outline',
                            position: 'top-right',
                            type: 'success',
                            icon: 'check',
                            duration: 2000
                        });

                    } else {
                        this.$toasted.show(response.message, {
                            theme: 'outline',
                            position: 'top-right',
                            type: 'success',
                            icon: 'check',
                            duration: 2000
                        });
                    }
                })
                .catch(error => {
                    this.$toasted.show(error.message, {
                        theme: 'outline',
                        position: 'top-right',
                        type: 'success',
                        icon: 'check',
                        duration: 2000
                    });
                })
                .finally(() => {
                    this.show = false;
                    this.getProductdata();
                })
        }
    },
    async mounted() {
        this.getProductdata();
        this.imageLink = API_URL + '/images/'
        document.title = 'Product';
    },
    // computed(){
    //      forfirefox(event){
    //         if(navigator.platform.indexOf())
    //      } 
    // }
}
</script>

<style scoped>
::-webkit-input-placeholder {
    font-size: 14px;
}

.fullwidthImage {

    width: -webkit-fill-available;
}

/* .img-fluid {
    max-width: 430px !important;
    height: 250px !important;
} */

.product_descreption_width {
    white-space: nowrap;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_quantity_width {
    white-space: nowrap;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: red;
}

.product_name_width {
    white-space: nowrap;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
