<template>
    <div>
        <Breadcrumbs main="Order" title="Order History" />
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="media">
                        <div class="form-inline">
                            <div class="form-group mb-0">
                                <h5 class="card-title">Order History</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div v-if="isNotFound">
                    <div v-if="orderlist.length == 0" class="m-t-20 m-b-20">
                        <h5 class="text-muted text-center">No Data Found!</h5>
                    </div>
                    <div v-else class="datatable-vue m-0">

                        <div class="row filter-smart">
                            <div class="col-sm-3">
                                <input class="form-control" v-model="filters.order_id.value" placeholder="Order Id" />
                            </div>
                            <div class="col-sm-3">
                                <input class="form-control" v-model="filters.fullName.value" placeholder="Customer Name" />
                            </div>
                            <div class="col-sm-3">
                                <input class="form-control" v-model="filters.status.value" placeholder="Status" />
                            </div>
                        </div>

                        <div class="table-responsive vue-smart mb-3">

                            <v-table :data="orderlist" class="table" :currentPage.sync="filter.currentPage" :pageSize="10"
                                @totalPagesChanged="filter.totalPages = $event" :filters="filters">

                                <thead slot="head">
                                    <v-th sortKey="srno">Sr.no</v-th>
                                    <v-th sortKey="order_id">Order Id</v-th>
                                    <v-th sortKey="fullName">Customer Name</v-th>
                                    <v-th sortKey="status">Status</v-th>
                                    <v-th sortKey="price">Amount</v-th>
                                    <v-th>Action</v-th>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">
                                    <tr v-for="row in displayData" :key="row.id">
                                        <td>{{ row.srno }}</td>
                                        <td>{{ row.order_id }}</td>
                                        <td>{{ row.fullName }}</td>
                                        <td>
                                            <b-badge pill :class="'status-' + row.status" class="fs-14" variant="light"
                                                >{{ row.status }}</b-badge>
                                        </td>
                                        <td>{{ row.price }}</td>
                                        <td>
                                            <router-link :to="{ name: 'invoice', query: { order_id: row.order_id } }">
                                                <b-button id="default-xs-light" class="btn-xs mr-2" variant="light">
                                                    <i class="fa fa-regular fa-eye feather-20 "></i>
                                                </b-button>
                                            </router-link>

                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <smart-pagination :currentPage.sync="filter.currentPage" :totalPages="filter.totalPages" />
                        </div>

                    </div>
                </div>
                </div>
            </div>
            <!-- loader -->
            <div class="loader-wrapper" :class="{ 'loderhide': !show }">
                <div class="loader-index">
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    orderList
} from "@/api/OrderApi"
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
export default {
    data() {
        return {
            show: false,
            orderlist: [],
            filter: {
                currentPage: 1,
                totalPages: 0,
            },
            filters: {
                order_id: {
                    value: '',
                    keys: ['order_id']
                },
                fullName: {
                    value: '',
                    keys: ['fullName']
                },
                status: {
                    value: '',
                    keys: ['status']
                },
                price: {
                    value: '',
                    keys: ['price']
                }
            },
            displayData: [],
            isNotFound:false
        }
    },
    methods: {
        getdataOrder() {
            this.show = true;
            orderList().then((response) => {
                if (response.status === ResponseStatusEnum.SUCCESS) {
                    this.order = response.data
                    this.order.forEach((element, index) => {
                        let tempObject = {
                            srno: index + 1,
                            order_id: element.order_id,
                            fullName: element.first_name + " " + element.last_name,
                            status: element.status,
                            price: "MMK."  + element.price,
                        }
                        this.orderlist.push(tempObject)
                    })
                } else {
                    this.$toasted.show(response.message, {
                        theme: 'outline',
                        position: 'top-right',
                        type: 'error',
                        icon: 'check',
                        duration: 2000
                    });

                }
            })
                .catch((error) => {
                    this.$toasted.show(error.response.data.message, {
                        theme: 'outline',
                        position: 'top-right',
                        type: 'error',
                        icon: 'check',
                        duration: 2000
                    });
                })
                .finally(() => {
                    this.show = false;
                    this.isNotFound=true;
                })
        },
    },
    mounted() {
        this.getdataOrder()
        document.title = 'Order';
    }
}
</script>

<style>
.badge-secondary {
    background-color: none !important;
}

.status-Pending {
    background-color: rgba(250, 139, 12, 0.1) !important;
    color: #fa8b0c;
}

.status-Confirmed {
    background-color: rgb(41 221 212 / 14%) !important;
    color: #4db6c8;
}

.status-Failed {
    background-color: rgb(235 45 70 / 10%);
    color: #f8092e;
}

.status-Success {
    background-color: rgb(218 245 170 / 56%);
    color: #3dc238;
}

.status-Approve {
    background-color: rgb(42 240 77 / 10%);
    color: #0ae52b;
}

.status-Processing {
    background-color: rgb(182 33 197 / 10%);
    color: #f41ff5;
}

.status-Rejected {
    background-color: rgba(255, 77, 79, 0.1);
    color: #ff4d4f;
}
</style>
