<template>
<li class="profile-nav onhover-dropdown p-0 mr-0">
    <div class="media profile-media">
        <img class="b-r-10" src="../../assets/images/dashboard/profile.jpg" alt="" />
        <div class="media-body">
            <span>Admin</span>
            <p class="mb-0 font-roboto">
               <feather type="chevron-down"></feather>
            </p>
        </div>
    </div>
    <ul class="profile-dropdown onhover-show-div p-t-10 p-b-10" >
        <li @click="logout" class="">
            <a>
                <feather type="log-in"></feather><span>Log out</span>
            </a>
        </li>
    </ul>
</li>
</template>

<script>

export default {
    name: 'Profile',
    methods: {
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('role');
            this.$router.push("/auth/login")
        }
    }
};
</script>
