import apiService from "../services/ApiService.js"

import {
    LOGIN,
} from "../api/ApiRoutes.js"

export const login = async (data) => {
    const response = await apiService.post(LOGIN, data)
    return response;
};
