<template>
    <div>
        <tab-content title="Data" :before-change="validateAsync1">
                                        <div class="setup-content" id="step-2">
        <div class="row">
            <div class="col-xs-4 col-sm-4">
                <div class="form-group">
                    <label class="control-label">Composition<span class="text-danger f-w-900">*</span></label>
                    <input class="form-control" v-model="$v.composition.$model" type="text" placeholder="Enter the Composition" required="required">
                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.composition.required !== 'undefined' && $v.composition.required == '' && $v.composition.$error">
                        Composition is required
                    </div>
                </div>
            </div>
            <div class="col-xs-4 col-sm-4">
                <div class="form-group">
                    <label class="control-label">Presentation<span class="text-danger f-w-900">*</span></label>
                    <input class="form-control" v-model="$v.presentation.$model" type="text" placeholder="Enter the Presentation" required="required">
                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.presentation.required !== 'undefined' && $v.presentation.required == '' && $v.presentation.$error">
                        Presentation is required
                    </div>
                </div>
            </div>
            <div class="col-xs-4 col-sm-4">
                <div class="form-group">
                    <label class="control-label">Storage<span class="text-danger f-w-900">*</span></label>
                    <input class="form-control" v-model="$v.storage.$model" type="text" placeholder="Enter the Storage" required="required">
                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.storage.required !== 'undefined' && $v.storage.required == '' && $v.storage.$error">
                        Storage is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 col-sm-4">
                <div class="form-group">
                    <label class="control-label">Indication<span class="text-danger f-w-900">*</span></label>
                    <input class="form-control" v-model="$v.indication.$model" type="text" placeholder="Enter the Indication" required="required">
                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.indication.required !== 'undefined' && $v.indication.required == '' && $v.indication.$error">
                        Indication is required
                    </div>
                </div>
            </div>
            <div class="col-xs-4 col-sm-4">
                <div class="form-group">
                    <label class="control-label">Dose<span class="text-danger f-w-900">*</span></label>
                    <input class="form-control" v-model="$v.dose.$model" type="text" placeholder="Enter the Dose" required="required">
                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.dose.required !== 'undefined' && $v.dose.required == '' && $v.dose.$error">
                        Dose is required
                    </div>
                </div>
            </div>
            <div class="col-xs-4 col-sm-4">
                <div class="form-group">
                    <label class="control-label">Shelf life<span class="text-danger f-w-900">*</span></label>
                    <input class="form-control" v-model="$v.shelflife.$model" type="text" placeholder="Enter the Shelf life" required="required" >
                    <div class="validation-div mb-2 text-danger" v-if="typeof $v.shelflife.required !== 'undefined' && $v.shelflife.required == '' && $v.shelflife.$error">
                        Shelf life is required
                    </div>
                </div>
            </div>
         
        </div>
        </div>
        </tab-content>
    </div>
    </template>
    
    <script>
    import {
        required
    } from "vuelidate/lib/validators";
    export default {
        data() {
            return {
                composition: '1',
                presentation: '1',
                storage: '1',
                dose: '1',
                shelflife: '1',
                categoryselect: '',
                imgview: '',
                indication: '1',
                form:{},
            }
        },
        validations() {
            return {
                composition: {
                    required
                },
                presentation: {
                    required
                },
                storage: {
                    required
                },
                indication: {
                    required
                },
                dose: {
                    required
                },
                shelflife: {
                    required
                },
            }
        },
        methods: {
        
            validateAsync1: function () {
                this.form.composition = this.composition;
            this.form.presentation = this.presentation;
            this.form.storage = this.storage;
            this.form.indication = this.indication;
            this.form.dose = this.dose;
            this.form.shelflife = this.shelflife;
               
                this.$emit('form-submitted', this.form);
                this.$v.$touch()
                return !(this.$v.composition.$invalid || this.$v.presentation.$invalid || this.$v.storage.$invalid || this.$v.indication.$invalid || this.$v.dose.$invalid || this.$v.shelflife.$invalid);
            },
          
        },
        props:{
            composition: String,
            presentation: String,
            storage: String,
            indication: String,
            dose: String,
            shelflife: String,
        }
      
    }
    </script>
    