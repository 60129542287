<template>
<div class="mode">
    <feather type="moon" v-show="mixLayout == 'light-only'" @click="customizeMixLayout('dark-only')">
    </feather>
    <feather type="sun" v-show="mixLayout == 'dark-only'" @click="customizeMixLayout('light-only')"></feather>
</div>
</template>

<script>
export default {
    name: 'Mode',
    data() {
        return {
            mixLayout: 'light-only',
        };
    },
    methods: {
        customizeMixLayout(val) {
            this.mixLayout = val;
            this.$store.dispatch('layout/setLayout', val);
        },
    },
};
</script>
