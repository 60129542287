<template>
<div>
    <Breadcrumbs main="Prescription" title="Prescription Requests" />
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                    <div class="media">
                        <div class="form-inline">
                            <div class="form-group mb-0">
                                <h5 class="card-title">Prescription</h5>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="card-body">
                <div class="datatable-vue m-0 ">
                    <div class="table-responsive vue-smart">
                        <div v-if="isNotFound">
                        <div v-if="prescriptionList.length  == 0" class="m-t-20 m-b-20">
                            <h5 class="text-muted text-center">No Data Found!</h5>
                        </div>
                        <div v-else class="mb-3 mt-2">
                            <v-table :data="prescriptionList" class="table" :currentPage.sync="filter.currentPage" :pageSize="10" @totalPagesChanged="filter.totalPages = $event" :filters="filters">

                                <thead slot="head">
                                    <v-th sortKey="srno">Sr.no</v-th>
                                    <v-th sortKey="name">Customer Name</v-th>
                                    <v-th sortKey="status">Status</v-th>
                                    <v-th sortKey="none">Action</v-th>
                                </thead>

                                <tbody slot="body" slot-scope="{displayData}">
                                    <tr v-for="(row, index) in displayData" :key="row.id">
                                        <td>{{ (index+1) }}</td>
                                        <td>{{ row.first_name }} {{ row.last_name }}</td>
                                        <td>
                                            <h5>
                                                <b-badge pill class="status-Approve" variant="light" v-if="row.status == 1">Approved</b-badge>
                                                <b-badge pill class="status-Rejected" variant="light" v-else-if="row.status == 0">Rejected</b-badge>
                                                <b-badge pill class="status-Pending" variant="light" v-else>Pending</b-badge>
                                            </h5>
                                        </td>
                                        <td>
                                            <b-button :id="`viewDetails-${index}`" class="btn-xs mr-2" variant="light" @click="viewDetails(row.id)">
                                                <i class="fa fa-regular fa-eye feather-20 ml-1"></i>
                                            </b-button>
                                            <b-tooltip :target="`viewDetails-${index}`" triggers="hover" placement="right">View Details</b-tooltip>

                                            <b-button v-if="row.status !== 1" :id="`accept-${index}`" class="btn-xs mr-2" variant="light" @click="acceptPrescription(row)">
                                                <feather type="check-square" style="color:green" sixe='20'></feather>
                                            </b-button>
                                            <b-tooltip :target="`accept-${index}`" triggers="hover" placement="top">Accept Prescription</b-tooltip>

                                            <b-button v-if="row.status !== 1" :id="`reject-${index}`" class="btn-xs mr-2" variant="light" @click="rejectPrescriptionmodal(row)">
                                                <feather type="x-square" style="color:red" sixe='20'></feather>
                                            </b-button>
                                            <b-tooltip :target="`reject-${index}`" triggers="hover" placement="top">Reject Prescription</b-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <smart-pagination :currentPage.sync="filter.currentPage" :totalPages="filter.totalPages" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal ref="my-modal" centered title="View Prescription Details" class="theme-modal" hide-footer style=" background: aliceblue !important;">
                <form @submit.stop.prevent="handleSubmit" class="">
                    <div class="row">
                        <div class="col-sm-4">
                            <b class="col-form-label">Name:</b>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="f-w-100">{{prescriptionDetails[0]?.first_name}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <b class="col-form-label">Product Name
                                :</b>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="f-w-100">{{prescriptionDetails[0]?.productName}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <b class="col-form-label">Price:</b>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="f-w-100">{{prescriptionDetails[0]?.price}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <b class="col-form-label">Quantity:</b>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="f-w-100">{{prescriptionDetails[0]?.quantity}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <b class="col-form-label">Prescription:</b>
                        </div>
                        <div class="col-sm-6">
                            <img class="img-200" :src="imageLink + prescriptionDetails[0]?.prescription" alt=" ">
                        </div>
                    </div>
                </form>
            </b-modal>

            <b-modal ref="reason-modal" centered title="View Prescription Details" class="theme-modal" hide-footer>
                <div class="row">
                    <div class="col-sm-4">
                        <b class="col-form-label">Reason:</b>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input class="form-control" type="text" v-model="reason" placeholder="Please Enter Reason" />
                        </div>
                    </div>
                </div>
                <button class="btn btn-secondary" type="button" @click="rejectPrescription(rejectdata)">Reject</button>
            </b-modal>
        </div>

        <!-- loader code -->
        <div class="loader-wrapper" :class="{'loderhide' : !show }">
            <div class="loader-index">
                <span></span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    API_URL
} from "../../services/ApiService";
import {
    detailsOfPrescription,
    prescriptionApproval,
    viewApprovalList
} from "@/api/prescriptionApi";
export default {
    data() {
        return {
            show: false,
            viewdetails: false,
            prescriptionList: [],
            filter: {
                currentPage: 1,
                totalPages: 0,
            },
            filters: {
                first_name: {
                    value: '',
                    keys: ['first_name']
                },
                status: {
                    value: '',
                    keys: ['status']
                }
            },
            getId: '',
            prescriptionDetails: [],
            reason: '',
            imageLink: '',
            first_name: '',
            status: '',
            rejectdata: '',
            isNotFound:false
        }
    },
    methods: {
        success_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'success',
                icon: 'check',
                duration: 2000
            });
        },
        error_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'error',
                icon: 'times',
                duration: 2000
            });
        },
        viewDetails: function (id) {
            this.show = true;
            detailsOfPrescription(id)
                .then((response) => {
                    if (response.status == "success") {
                        this.show = false;
                        this.$refs['my-modal'].show()
                        this.prescriptionDetails = response.data;
                    } else {
                        this.error_with_icon(response.message);
                    }
                })
                .catch((error) => {
                    this.error_with_icon(error.response.data.message);
                })
                .finally(() => {
                    this.show = false;
                });

        },
        getApprovaldata() {
            this.show = true;
            viewApprovalList()
                .then((response) => {
                    if (response.status == 'success') {
                        this.prescriptionList = response.data
                        this.prescriptionList.forEach((item, index) => ({
                            ...item,
                            srno: index + 1,
                        }))

                    } else {
                        this.error_with_icon(response.message);
                    }
                })
                .catch((error) => {
                    this.error_with_icon(error.response.data.message);
                })
                .finally(() => {
                    this.show = false;
                    this.isNotFound=true;
                })
        },
        acceptPrescription(isdata) {
            this.$refs['my-modal'].hide()
            this.show = true;
            let data = {
                status: parseInt(1),
                id: isdata.id,
            };
            prescriptionApproval(data).then((response) => {
                    if (response.data.status == "success") {
                        this.success_with_icon(response.data.message);
                    } else {
                        this.error_with_icon(response.data.message);
                    }
                })

                .finally(() => {
                    this.show = false;
                    this.getApprovaldata();
                })
        },
        rejectPrescription(isdata) {
            this.$refs['reason-modal'].hide()
            this.show = true;
            if (this.reason != '') {
                let data = {
                    status: parseInt(0),
                    id: isdata.id,
                    reason: this.reason
                };
                prescriptionApproval(data).then((response) => {
                        if (response.data.status == "success") {
                            this.success_with_icon(response.data.message);
                        } else {
                            this.error_with_icon(response.data.message);
                        }
                    })

                    .finally(() => {
                        this.show = false;
                        this.getApprovaldata();
                    })
            }
        },
        rejectPrescriptionmodal: function (data) {
            this.$refs['reason-modal'].show()
            return (this.rejectdata = data)
        }
    },
    mounted() {
        this.getApprovaldata();
        this.imageLink = API_URL + "/images/";
        document.title = 'Prescription';
    }
}
</script>

<style>
.badge-secondary {
    background-color: none !important;
}

.status-Pending {
    background-color: rgba(250, 139, 12, 0.1) !important;
    color: #fa8b0c;
}

.status-Confirmed {
    background-color: rgb(41 221 212 / 14%) !important;
    color: #4db6c8;
}

.status-Failed {
    background-color: rgb(235 45 70 / 10%);
    color: #f8092e;
}

.status-Success {
    background-color: rgb(218 245 170 / 56%);
    color: #3dc238;
}

.status-Approve {
    background-color: rgb(42 240 77 / 10%) !important;
    color: #0ae52b !important;
}

.status-Processing {
    background-color: rgb(182 33 197 / 10%);
    color: #f41ff5;
}

.status-Rejected {
    background-color: rgba(255, 77, 79, 0.1) !important;
    color: #ff4d4f !important;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    padding: 1rem;
    /* background-color: aliceblue !important; */
}

.modal-header {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    /* background-color: rgb(205, 221, 226) !important; */
}
</style>
