<template>
  <div class="customizer-header">
    <i class="icofont-close icon-close" @click="closecustomizer()"></i>
    <h5>Customizer</h5>
    <p class="mb-0">
      Try It Real Time <i class="fa fa-thumbs-o-up txt-primary"></i>
    </p>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'CustomizerConfiguration',
    data() {
      return {
        styleObject: {
          position: 'fixed',
          left: '0',
          top: '0',
          opacity: '0',
        },
      };
    },
    computed: {
      ...mapState({
        customizer: (state) => state.menu.customizer,
        data: (state) => JSON.stringify(state.layout.layout),
        layout: (state) => state.layout.layout,
      }),
    },
    methods: {
      closecustomizer() {
        this.$store.state.menu.customizer = '';
      },
      copyText() {
        this.$refs.layout.select();
        document.execCommand('copy');
        this.$toasted.show('Code Copied to clipboard', {
          theme: 'outline',
          position: 'top-right',
          type: 'default',
          duration: 2000,
        });
      },
    },
  };
</script>
