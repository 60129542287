import apiService from "../services/ApiService";

import {ORDER_LIST,INVOICE_DATA} from "@/api/ApiRoutes"

export const orderList = async() => {
    const response = await apiService.get(ORDER_LIST);
    return response.data;
};
export const invoice_Data = async(order_id) => {
    const response = await apiService.get(`${INVOICE_DATA}/${order_id}`);
    return response.data;
};