<template>
    <div>
        <tab-content title="General" :before-change="validateAsync">
            <div class="setup-content" id="step-1">
                <div class="row">
                    <div class="col-xs-6 col-sm-6">
                        <div class="form-group">
                            <label class="control-label">Product Name<span class="text-danger f-w-900">*</span></label>
                            <input class="form-control" v-model="$v.Productname.$model" type="text"
                                placeholder="Enter the Product Name" required="required">
                            <div class="validation-div mb-2 text-danger"
                                v-if="typeof $v.Productname.required !== 'undefined' && $v.Productname.required == '' && $v.Productname.$error">
                                Product name is required
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-6">
                        <div class="form-group">
                            <label class="control-label">Description<span class="text-danger f-w-900">*</span></label>
                            <textarea class="form-control" v-model="$v.Description.$model" type="text" rows="1"
                                placeholder="Enter the Description" required="required" />
                            <div class="validation-div mb-2 text-danger"
                                v-if="typeof $v.Description.required !== 'undefined' && $v.Description.required == '' && $v.Description.$error">
                                Description is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 col-sm-4">
                        <div>
                            <label class="form-label">Category<span class="text-danger f-w-900">*</span></label>
                            <select class="form-control btn-square" v-model="$v.categoryselect.$model">
                                <option v-for="item of category" :key="item.id" :value="item.id">
                                    {{ item.name }}</option>
                                <option :value="categoryselect ? categoryselect : ''" disabled selected hidden>
                                    {{ categoryselect ? categoryselect : 'Select' }}
                                </option>
                            </select>

                        </div>
                        <div class="validation-div mb-2 text-danger"
                            v-if="typeof $v.categoryselect.required !== 'undefined' && $v.categoryselect.required == '' && $v.categoryselect.$error">
                            Category is required.
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4">
                        <div class="form-group">
                            <label class="control-label">Price(MMK)<span class="text-danger f-w-900">*</span></label>
                            <input min="0"
                                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                class="form-control" v-model="$v.Price.$model" type="number" placeholder="Enter the Price"
                                required="required">
                            <div class="validation-div mb-2 text-danger"
                                v-if="typeof $v.Price.required !== 'undefined' && $v.Price.required == '' && $v.Price.$error">
                                Price is required
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4">
                        <div class="form-group">
                            <label class="control-label">Quantity<span class="text-danger f-w-900">*</span></label>
                            <input min="0"
                                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                class="form-control" v-model="$v.Quantity.$model" type="number"
                                placeholder="Enter the Quantity" required="required">
                            <div class="validation-div mb-2 text-danger"
                                v-if="typeof $v.Quantity.required !== 'undefined' && $v.Quantity.required == '' && $v.Quantity.$error">
                                Quantity is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 col-sm-4">
                        <div class="form-group">
                            <label class="control-label">Meta Tag Title<span class="text-danger f-w-900">*</span></label>
                            <input class="form-control" v-model="$v.Metatitle.$model" type="text"
                                placeholder="Enter Meta Tag Title " required="required">
                            <div class="validation-div mb-2 text-danger"
                                v-if="typeof $v.Metatitle.required !== 'undefined' && $v.Metatitle.required == '' && $v.Metatitle.$error">
                                Meta Tag Title is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4">
                        <div class="form-group">
                            <label class="control-label">Meta Tag Description</label>
                            <input class="form-control" v-model="Metadescription" type="text"
                                placeholder="Enter Meta Tag Description " required="required">
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4">
                        <div class="form-group">
                            <label class="control-label">Meta Tag Keywords</label>
                            <input class="form-control" v-model="Metatag" type="text" placeholder="Enter Meta Tag Keywords "
                                required="required">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group m-b-5 m-t-5">
                            <label class="control-label">Prescription Required<span
                                    class="text-danger f-w-900">*</span></label>
                        </div>
                        <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="radio radio-primary d-inline-block">
                                <b-form-radio name="radio3" value="true" v-model="isprescription">Yes</b-form-radio>
                            </div>
                            <div class="radio radio-primary d-inline-block">
                                <b-form-radio name="radio3" value="false" v-model="isprescription">No</b-form-radio>
                            </div>
                            <div class="validation-div mb-2 text-danger"
                                v-if="typeof $v.isprescription.required !== 'undefined' && $v.isprescription.required == '' && $v.isprescription.$error">
                                Prescription is required.
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </tab-content>
    </div>
</template>

<script>
import {
    CategoryList
} from '@/api/CategoryApi';
import {
    required
} from "vuelidate/lib/validators";
export default {
    data() {
        return {
            form: {},
            Productname: "",
            category: [],
            Description: '',
            Price: '',
            Quantity: '',
            Metatitle: '',
            Metadescription: '',
            Metatag: '',
            categoryselect: '',
            isprescription: null
        }
    },
    validations() {
        return {
            Productname: {
                required
            },
            Description: {
                required
            },
            categoryselect: {
                required
            },
            Price: {
                required
            },
            Quantity: {
                required
            },
            Metatitle: {
                required
            },
            isprescription: {
                required
            },
        }
    },
    methods: {

        validateAsync: function () {
            this.form.Productname = this.Productname;
            this.form.Description = this.Description;
            this.form.categoryselect = this.categoryselect;
            this.form.Price = this.Price;
            this.form.Quantity = this.Quantity;
            this.form.Metatitle = this.Metatitle;
            this.form.isprescription = this.isprescription;


            this.$emit('form-general-submitted', this.form);
            this.$v.$touch()
            return !(this.$v.Productname.$invalid || this.$v.Description.$invalid || this.$v.Price.$invalid || this.$v.categoryselect.$invalid || this.$v.Quantity.$invalid || this.$v.Metatitle.$invalid || this.$v.isprescription.$invalid);
        },
        getcategory() {
            this.show = true;
            const data = {
                model: "Category",
                condition: {
                    is_archived: false,
                },
            }
            CategoryList(data).then((response) => {
                this.category = response.data
                this.show = false;
                // this.getEditdata();
            })
        },

    },
    mounted() {
        this.getcategory();
    },
    props: {
        Productname: String,
        Description: String,
        Price: String,
        categoryselect: String,
        Quantity: String,
        Metatitle: String,
        isprescription: String
    }
}
</script>
