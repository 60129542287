import moment from "moment"

export const dateFormat = (date) => {
    return moment(date).format("DD-MM-YYYY");
}

export const validation = ( value )=>{
    return (`${value} is Required field`)
}

