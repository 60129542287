<template>
<div>
    <Breadcrumbs title="Reports" />
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Reports</h5>
                    </div>

                    <div class="card-body dropdown-basic mb-4">
                        <div class="row">
                            <div class="col-sm-3">
                                <label>Select Report Type</label>
                                <b-form-select v-model="selected" :options="options" @change="filterReport()"></b-form-select>
                            </div>
                            <div class="box"> 
                                    <label>Select Date</label><br>
                                    <date-picker class="" v-model="dates" range @change="datechange()" placeholder="Select date range" value-type="YYYY/MM/DD" format="DD/MM/YYYY"></date-picker>
                            </div>
                            <div class="col-sm-3" v-if="isStatus">
                                <label>Select Status</label>
                                <b-form-select v-model="statusselect" :options="status"></b-form-select>
                            </div>
                            <div class="col-sm-3" v-if="isCustomer">
                                <label>Customer Name</label>
                                <input class="form-control" v-model="customer" type="text" placeholder="Enter the Customer Name">
                            </div>
                            <div class="col-sm-2 m-t-30">
                                <div class="btn btn-primary " @click="filterReport()">
                                    Submit
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xl-12" v-if="selected == 'purchasedproductreport' ">
                <div class="card">
                    <div class="card-header">
                        <h5>Purchased product Report </h5>
                    </div>

                    <div class="card-body dropdown-basic">
                        <div class="table-responsive vue-smart">
                            <div v-if="isNotFound">
                            <div v-if="purchasedProductData.length == 0">
                                <h5 class="text-muted text-center">No Data Found!</h5>
                            </div>

                            <v-table v-else :data="purchasedProductData" class="table" :pageSize="10">

                                <thead slot="head">
                                    <v-th>Sr.no</v-th>
                                    <v-th>Name</v-th>
                                    <v-th>Quantity</v-th>
                                    <v-th>Price</v-th>
                                    <v-th>Total</v-th>
                                </thead>
                                <tbody slot="body">
                                    <tr v-for="(row, index) in purchasedProductData" :key="row.id">
                                        <td>{{(index+1)}}</td>
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.quantity }}</td>
                                        <td>{{ ('MMK.'+row.price )}}</td>
                                        <td>{{ ('MMK.'+row.total) }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12" v-if="selected == 'vendorreport' ">
                <div class="card">
                    <div class="card-header">
                        <h5>Vendor Report </h5>
                    </div>

                    <div class="card-body dropdown-basic">
                        <div class="table-responsive vue-smart">
                            <div v-if="isNotFound">
                            <div v-if="vendorReportData.length == 0">
                                <h5 class="text-muted text-center">No Data Found!</h5>
                            </div>

                            <v-table v-else :data="vendorReportData" class="table" :pageSize="10">
                                <thead slot="head">
                                    <v-th>Sr.no</v-th>
                                    <v-th>Name</v-th>
                                    <v-th>Total</v-th>
                                </thead>
                                <tbody slot="body">
                                    <tr v-for="(row, index) in vendorReportData" :key="row.id">
                                        <td>{{ (index+1) }}</td>
                                        <td>{{ row.first_name }}</td>
                                        <td>{{ ('MMK.' + row.total) }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12" v-if="selected == 'customerreport' ">
                <div class="card">
                    <div class="card-header">
                        <h5>Customer Activity Report </h5>
                    </div>

                    <div class="card-body dropdown-basic">
                        <div class="table-responsive vue-smart">
                            <div v-if="isNotFound">
                            <div v-if="customerActivityData.length == 0">
                                <h5 class="text-muted text-center">No Data Found!</h5>
                            </div>

                            <v-table v-else :data="customerActivityData" class="table" :pageSize="10">
                                <thead slot="head">
                                    <v-th>Sr.no</v-th>
                                    <v-th>Name</v-th>
                                    <v-th>Email Id</v-th>
                                    <v-th>Phone no</v-th>
                                </thead>
                                <tbody slot="body">
                                    <tr v-for="(row, index) in customerActivityData" :key="index">
                                        <td>{{ (index+1) }}</td>
                                        <td>{{ row.first_name }}</td>
                                        <td>{{ row.email_id }}</td>
                                        <td>{{ row.phone_no }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12" v-if="selected == 'customertransactionreport' ">
                <div class="card">
                    <div class="card-header">
                        <h5>Customer Transaction Report </h5>
                    </div>

                    <div class="card-body dropdown-basic">
                        <div class="table-responsive vue-smart">
                            <div v-if="isNotFound">
                            <div v-if="customerTransactionfinaldata.length == 0">
                                <h5 class="text-muted text-center">No Data Found!</h5>
                            </div>

                            <v-table v-else :data="customerTransactionfinaldata" class="table" :pageSize="10">

                                <thead slot="head">
                                    <v-th>Sr.no</v-th>
                                    <v-th>Name</v-th>
                                    <v-th>Email Id</v-th>
                                    <v-th>Total Amount</v-th>
                                    <v-th>Date</v-th>
                                </thead>
                                <tbody slot="body">
                                    <tr v-for="(row, index) in customerTransactionfinaldata" :key="row.id">
                                        <td>{{ (index+1) }}</td>
                                        <td>{{ row.customerName }}</td>
                                        <td>{{ row.Email }}</td>
                                        <td>{{ row.totalAmount }}</td>
                                        <td>{{ row.date }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12" v-if="selected == 'salesreport' ">
                <div class="card">
                    <div class="card-header">
                        <h5>Sales Report </h5>
                    </div>

                    <div class="card-body dropdown-basic">
                        <div class="table-responsive vue-smart">
                            <div v-if="isNotFound">
                            <div v-if="salesReportData.length == 0">
                                <h5 class="text-muted text-center">No Data Found!</h5>
                            </div>

                            <v-table v-else :data="salesReportData" class="table" :pageSize="10">

                                <thead slot="head">
                                    <v-th>Sr.no</v-th>
                                    <v-th>Number of Orders</v-th>
                                    <v-th>Number of Products</v-th>
                                    <v-th>Tax</v-th>
                                    <v-th>Total</v-th>
                                </thead>
                                <tbody slot="body">
                                    <tr v-for="(row, index) in salesReportData" :key="row.id">
                                        <td>{{ (index+1) }}</td>
                                        <td>{{ row.noOfOrder }}</td>
                                        <td>{{ row.noOfProduct }}</td>
                                        <td>{{ row.tax }}</td>
                                        <td>{{ ('MMK.'+row.total) }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-12" v-if="selected == 'customerorderreport' ">
                <div class="card">
                    <div class="card-header">
                        <h5>Customer Order Report </h5>
                    </div>
                    <div class="card-body dropdown-basic">
                        <div class="table-responsive vue-smart">
                            <div v-if="isNotFound">
                            <div v-if="customerOrderData.length == 0">
                                <h5 class="text-muted text-center">No Data Found!</h5>
                            </div>
                            <v-table v-else :data="customerOrderData" class="table" :pageSize="10">

                                <thead slot="head">
                                    <v-th>Sr.no</v-th>
                                    <v-th>Name</v-th>
                                    <v-th>Email Id</v-th>
                                    <v-th>No of Orders</v-th>
                                    <v-th>No of Products</v-th>
                                    <v-th>Status</v-th>
                                    <v-th>Price</v-th>
                                    <v-th>Order</v-th>
                                    <v-th>Total</v-th>
                                </thead>
                                <tbody slot="body">
                                    <tr v-for="(row, index) in customerOrderData" :key="row.id">
                                        <td>{{ (index+1) }}</td>
                                        <td>{{ row.customerName }}</td>
                                        <td>{{ row.email }}</td>
                                        <td>{{ row.noOfOrder }}</td>
                                        <td>{{ row.noOfProduct }}</td>
                                        <td>{{ row.status }}</td>
                                        <td>{{ ('MMK.'+ row.price) }}</td>
                                        <td>{{ row.order }}</td>
                                        <td>{{ ('MMK.' + row.total) }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- loader code -->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
        <div class="loader-index">
            <span></span>
        </div>
    </div>

</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    purchasedProductReport,
    vendorReport,
    customerOrderReport,
    customerTransactionReport,
    salesReport,
    customerActivityReport
} from '@/api/ReportApi'
import {
    dateFormat
} from '../../constants/comman'
export default {
    components: {
        DatePicker
    },
    data() {
        return {
            dates: '',
            show: false,
            customer: null,
            isCustomer: false,
            isStatus: false,
            basic: {
                currentPage: 1,
                totalPages: 0,
            },
            filters: {
                age: {
                    value: '',
                    keys: ['srno']
                },
                name: {
                    value: '',
                    keys: ['emailid']
                },
                status: {
                    value: '',
                    keys: ['phoneno']
                }
            },
            statusselect: null,
            displayData: [],
            selected: null,
            status: [],
            options: [{
                    value: null,
                    text: 'Select Report Type'
                }, {
                    value: 'customerreport',
                    text: 'Customer Report'
                },
                {
                    value: 'customertransactionreport',
                    text: 'Customer Transaction Report'
                },
                {
                    value: 'salesreport',
                    text: 'Sales Report'
                },
                {
                    value: 'customerorderreport',
                    text: 'Customer Order Report'
                },
                {
                    value: 'vendorreport',
                    text: 'Vendor Report'
                },
                {
                    value: 'purchasedproductreport',
                    text: 'Purchased Product Report'
                }
            ],
            purchasedProductData: [],
            vendorReportData: [],
            customerActivityData: [],
            customerTransactionData: [],
            salesReportData: [],
            customerTransactiondata: [],
            customerTransactionfinaldata: [],
            customerOrderData: [],
            isNotFound:false
        }
    },
    methods: {
        filterReport() {
            this.isNotFound=false;
            this.show = true;
            if (this.selected == 'purchasedproductreport') {

                this.isStatus = true;
                this.isCustomer = false;

                this.status = [{
                        text: "Select Status",
                        value: null,
                        disabled: true
                    },
                    {
                        text: "With Prescription",
                        value: true
                    },
                    {
                        text: "Without Prescription",
                        value: false
                    },
                    {
                        text: "All",
                        value: null
                    }
                ]

                let reportData = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[1],
                    "status": this.statusselect
                }
                purchasedProductReport(reportData).then((response) => {
                        if (response.status === ResponseStatusEnum.SUCCESS) {
                            this.purchasedProductData = response.data.product
                        }
                    })
                    .catch(error => {
                        this.$toastr.error(error.response.data.message);
                    })
                    .finally(() => {
                        this.show = false;
                        this.isNotFound=true;
                    })
            }
            if (this.selected == 'vendorreport') {

                this.isStatus = true;
                this.isCustomer = false;

                this.status = [{
                        text: "Select Status",
                        value: null,
                        disabled: true
                    },
                    {
                        text: "With Prescription",
                        value: true
                    },
                    {
                        text: "Without Prescription",
                        value: false
                    },
                    {
                        text: "All",
                        value: null
                    }
                ]

                const data = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[1],
                    "status": this.statusselect
                }
                vendorReport(data).then((response) => {
                        if (response.status === ResponseStatusEnum.SUCCESS) {
                            // this.vendorReportData = response?.data?.vendor
                            this.vendorReportData = response.data
                        }
                    })
                    .catch(error => {
                        this.$toastr.error(error.response.data.message);
                    })
                    .finally(() => {
                        this.show = false;
                        this.isNotFound=true;
                    })
            }
            if (this.selected == 'customerreport') {

                this.isStatus = true;

                this.status = [{
                        text: "Select Status",
                        value: null,
                        disabled: true
                    }, {
                        text: "Active",
                        value: 'Active'
                    },
                    {
                        text: "Inactive",
                        value: 'InActive'
                    },

                ]

                let data = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[1],
                    "status": this.statusselect,
                    "limit": 30
                }
                customerActivityReport(data).then((response) => {
                        if (response.status === ResponseStatusEnum.SUCCESS) {
                            this.customerActivityData = response.data
                        }
                    })
                    .catch(error => {
                        this.$toastr.error(error.response.data.message);
                    })
                    .finally(() => {
                        this.show = false;
                        this.isNotFound=true;
                    })
            }
            if (this.selected == 'salesreport') {

                this.isStatus = false;
                this.isCustomer = false;

                let data = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[1],
                }

                salesReport(data).then((response) => {
                        if (response.status === ResponseStatusEnum.SUCCESS) {
                            this.salesReportData = response.data
                        }
                    })
                    .catch(error => {
                        this.$toastr.error(error.response.data.message);
                    })
                    .finally(() => {
                        this.show = false;
                        this.isNotFound=true;
                    })
            }
            if (this.selected == 'customertransactionreport') {

                this.isStatus = false;
                this.isCustomer = true;

                let data = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[1],
                    "customerName": this.customer,
                    "sort": {
                        "first_name": "ASC",
                        "email_id": "ASC",
                        "total_amount": "ASC"
                    },
                    "limit": 10
                }
                customerTransactionReport(data).then((response) => {
                        if (response.status === ResponseStatusEnum.SUCCESS) {
                            this.customerTransactiondata = response.data

                            this.customerTransactionfinaldata = this.customerTransactiondata.map((item, index) => ({
                                ...item,
                                totalAmount: "MMK." + item.totalAmount,
                                date: dateFormat(item.date)

                            }))

                            customerTransactionfinaldata.push(this.customerTransactiondata)
                        }

                    })
                    .catch(error => {
                        this.$toastr.error(error.response.data.message);
                    })
                    .finally(() => {
                        this.show = false;
                        this.isNotFound=true;
                    })
            }
            if (this.selected == 'customerorderreport') {

                this.isStatus = true;

                this.status = [{
                        text: "Select Status",
                        value: null,
                        disabled: true
                    },
                    {
                        text: "Rejected",
                        value: "Rejected"
                    },
                    {
                        text: "Confirmed",
                        value: "Confirmed"

                    },
                    {
                        text: "Pending",
                        value: "Pending"
                    },
                    {
                        text: "Success",
                        value: "Success"
                    },
                    {
                        text: "Failed",
                        value: "Failed"
                    },
                    {
                        text: "Approved",
                        value: "Approved"
                    },
                    {
                        text: "Processing",
                        value: "Processing"
                    },

                ]

                let data = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[1],
                    "customerName": this.customer,
                    "orderStatus": this.statusselect
                }
                customerOrderReport(data).then((response) => {
                        if (response.status === ResponseStatusEnum.SUCCESS) {
                            this.customerOrderData = response.data
                        }
                    })
                    .catch(error => {
                        this.$toastr.error(error.response.data.message);
                    })
                    .finally(() => {
                        this.show = false;
                        this.isNotFound=true;
                    })
            }
        },
    },
    mounted() {
        document.title = 'Report';
    }
}
</script>

<style scoped>
.vfc-single-input,
.vfc-multiple-input input {
    font-size: inherit;
    -webkit-transition: width 200ms;
    transition: width 200ms;
    padding: 7px;
    width: 172px !important;
    color: #aaaaaa;
    border: 1px solid #ced4da !important;
    text-align: center;
    outline: none;
    border-radius: 0.25rem !important;
}

button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.55 !important;
    margin: 0;
}

.vfc-multiple-input input:last-child {
    border-radius: 0 10px 10px 0;
}
</style>
