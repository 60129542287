<template>
    <div>
        <Breadcrumbs main="Product" :title="title" />
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">

                            <form-wizard @on-validate="handleValidation" @on-complete="onComplete" color="#4466f2"
                                :title="title" subtitle="Split a complicated flow in multiple steps"
                                back-button-text="Go back!" next-button-text="Go next!" :finish-button-text="title">

                                <tab-content title="Image">
                                    <tabImage @addProductbtn="ActiveAddProduct" :image="savedImage" />
                                    <div class="row ">
                                        <div class="col-sm-4 col-md-12 ">
                                            <div v-for="img in savedImage" class="d-flex m-25">

                                                <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                                                    <div class="radio radio-primary d-inline-block">
                                                        <b-form-radio name="img" :value="img.image ? img.image : img"
                                                            v-model="$v.imageChoose.$model"></b-form-radio>
                                                    </div>
                                                </div>

                                                <img class="img-200 "
                                                    :src="img.image ? imageLink + img.image : imageLink + img"
                                                    :alt="imageLink + imgview" />

                                                <button class="btn" type="button" data-toggle="modal"
                                                    data-target="#exampleModalCenter" @click="deletemodalshow(id)">
                                                    <i class="fa fa-trash feather-20 ml-1"></i>
                                                </button>
                                            </div>


                                            <!-- <div>
                                                <img v-if="title == 'Edit Product'" class="img-200 m-45"
                                                    @error="replaceByDefault" :src="imageLink + imgview"
                                                    :alt="imageLink + imgview" />
                                                <img onerror="this.style.display='none'" class=" img-200 m-45"
                                                    :src="previewImage" :alt="previewImage" style="position:none" />
                                            </div> -->
                                            <div v-if="savedImage.length > 0">
                                                <div class="validation-div mb-2 text-danger"
                                                    v-if="typeof $v.imageChoose.required !== 'undefined' && $v.imageChoose.required == '' && $v.imageChoose.$error">
                                                    Prescription is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </tab-content>

                                <tabGeneral @form-general-submitted="tabGeneralData" :Productname="Productname"
                                    :Description="Description" :Price="Price" :categoryselect="categoryselect"
                                    :Quantity="Quantity" :Metatitle="Metatitle" :isprescription="isprescription" />

                                <tabData @form-submitted="tabTwoData" :composition="composition"
                                    :presentation="presentation" :storage="storage" :indication="indication" :dose="dose"
                                    :shelflife="shelflife" />

                                <template slot="footer" slot-scope="props">
                                    <div class="wizard-footer-left">
                                        <wizard-button v-if="props.activeTabIndex > 0" @click.native="props.prevTab()"
                                            :style="props.fillButtonStyle">Previous</wizard-button>
                                    </div>
                                    <div class="wizard-footer-right">
                                        <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()"
                                            class="wizard-footer-right" :style="props.fillButtonStyle">Next</wizard-button>

                                        <wizard-button v-else @click.native="onCompleted"
                                            class="wizard-footer-right finish-button" :style="props.fillButtonStyle">
                                            {{ props.isLastStep ? title : 'Next' }}</wizard-button>
                                    </div>
                                </template>
                            </form-wizard>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="deletemodal" centered class="theme-modal" hide-header hide-footer>
            <h6 class="p-t-20">Are you sure you want to Delete Image?</h6>

            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" @click="cancel()">Cancel</button>
                <button class="btn btn-primary" type="button" @click="DeleteImage(deleteId)">Yes</button>
            </div>
        </b-modal>

        <!-- loader -->
        <div class="loader-wrapper" :class="{ 'loderhide': !show }">
            <div class="loader-index">
                <span></span>
            </div>
        </div>

    </div>
</template>

<script>
import tabGeneral from "./tabGeneral.vue";
import tabData from "./tabData.vue";

import tabImage from "./tabImage.vue";
import {
    required
} from "vuelidate/lib/validators";
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    API_URL
} from "../../services/ApiService";
import {
    CategoryList
} from '@/api/CategoryApi';
import {
    productUpdate,
    productListEdit,
    addProduct,
    ProductImage
} from "@/api/ProductApi";
import onImageError from "@/constants/onImageError";
export default {
    mixins: [onImageError],
    data() {
        return {
            isprescription: '',
            show: false,
            file1: null,
            imagefiles: '',
            previewImage: undefined,
            Productname: "",
            category: [],
            Description: '',
            Price: '',
            Quantity: '',
            Metatitle: '',
            Metadescription: '',
            Metatag: '',
            composition: '',
            presentation: '',
            storage: '',
            dose: '',
            shelflife: '',
            categoryselect: '1',
            imgview: '',
            indication: '',
            maxSize: 25 * 1024 * 1024, //52,42,880
            imageFiles: [],
            selectedFileNames: [],
            files: null,
            formData: null,
            test: null,
            savedImage: [],
            selectedImages: '',
            disabledbtn: true,
            reloadComponent: 0,
            imageChoose: '',
            deletemodal: false,
            imageArray : []
        }
    },
    validations() {
        return {
            imageChoose: {
                required
            },
        }
    },
    components: {
        tabGeneral,
        tabImage,
        tabData
    },
    props: {
        title: {
            type: String,
            default: 'Add Product'
        }
    },

    created() {
        this.product_id = this.$route.query.product_id
        this.product_id ? disabledbtn = false : disabledbtn = true
    },
    methods: {
        cancel() {
            this.deletemodal = false
        },
        DeleteImage(id) {
            this.deletemodal = false
            // Api call will be done here
        },
        deletemodalshow: function (data) {
            this.deletemodal = true;
            this.deleteId = data.product_id
        },
        ActiveAddProduct(value, btnvalue) {
            this.savedImage.push(...value);
            this.disabledbtn = btnvalue
            this.reloadComponent++;
        },
        // validateAsync: function () {
        //     return !(this.$v.Productname.$invalid || this.$v.Description.$invalid || this.$v.Price.$invalid || this.$v.Quantity.$invalid || this.$v.Metatitle.$invalid || this.$v.categoryselect.$invalid || this.$v.isprescription.$invalid);
        // },
        handleValidation: function () {
            this.$v.$touch()
            if (this.$v.$invalid) { } else { }
        },
        success_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'success',
                icon: 'check',
                duration: 2000
            });
        },
        error_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'error',
                icon: 'times',
                duration: 2000
            });
        },
        getcategory() {
            this.show = true;
            const data = {
                model: "Category",
                condition: {
                    is_archived: false,
                },
            }
            CategoryList(data).then((response) => {
                this.category = response.data
                this.show = false;
                this.getEditdata();
            })
        },
        onComplete() {
            this.onCompleted()
        },
        tabGeneralData(value) {
            this.Productname = value.Productname
            this.Description = value.Description
            this.Price = value.Price
            this.Quantity = value.Quantity
            this.Metatitle = value.Metatitle
            this.Metadescription = value.Metadescription
            this.Metatag = value.Metatag
            this.categoryselect = value.categoryselect
            this.isprescription = value.isprescription
        },
        tabTwoData(value) {
            this.composition = value.composition;
            this.presentation = value.presentation;
            this.storage = value.storage;
            this.indication = value.indication;
            this.dose = value.dose;
            this.shelflife = value.shelflife;
        },
        onCompleted() {


            let data = {
                name: this.Productname,
                description: this.Description,
                price: this.Price,
                vendor_id: parseInt("1"),
                category_id: '1',
                is_prescription: this.isprescription,
                quantity: this.Quantity,
                metaTagTitle: this.Metatitle,
                metaTagDescription: this.Metadescription,
                metaTagKeywords: this.Metatag,
                referral_code: 'test',
                composition: this.composition,
                presentation: this.presentation,
                storage: this.storage,
                indication: this.indication,
                dose: this.dose,
                shelf_life: this.shelflife,
                image: this.savedImage,
                selectedImage: this.imageChoose
            }

            if (this.product_id) {

                this.savedImage.map((image)=>{
                    if(image.id){
                        this.imageArray.push(image.image)
                    }
                    else{
                        this.imageArray.push(image)
                    }
                })

                data.image = this.imageArray

                productUpdate(this.product_id, data).then((response) => {
                    if (response.data.status === ResponseStatusEnum.SUCCESS) {
                        this.$router.push('/admin/product')
                    } else {
                        this.error_with_icon(response.data.message)
                    }
                })
            }
            else {
                addProduct(data).then((response) => {
                    if (response.data.status === ResponseStatusEnum.SUCCESS) {
                        this.$router.push('/admin/product')
                    } else {
                        this.error_with_icon(response.data.message)
                    }
                })
            }
        },
        onUpload(event) {
            this.files = event.target.files;

            this.formData = new FormData();

            for (let i = 0; i < this.files.length; i++) {
                const selectedFile = this.files[i];

                if (selectedFile) {
                    const fileSize = selectedFile.size;

                    if (fileSize < this.maxSize) {
                        this.formData.append("image", this.files[i]);
                    }
                    else {
                        this.error_with_icon(`${selectedFile.name} File size exceeds 5 MB.`);
                    }
                    this.imageFiles.push(selectedFile);

                }
            }
        },
        getEditdata() {
            if (this.product_id) {
                this.show = true;
                this.id = this.product_id
                productListEdit(this.id).then((response) => {
                    if (response.data.status === ResponseStatusEnum.SUCCESS) {
                        this.Productname = response.data.data[0].name
                        this.Description = response.data.data[0].description
                        this.Price = response.data.data[0].price
                        this.Quantity = response.data.data[0].quantity
                        this.Metatitle = response.data.data[0].metaTagTitle
                        this.Metadescription = response.data.data[0].metaTagDescription
                        this.Metatag = response.data.data[0].metaTagKeywords
                        this.composition = response.data.data[0].composition
                        this.presentation = response.data.data[0].presentation
                        this.storage = response.data.data[0].storage
                        this.indication = response.data.data[0].indication
                        this.dose = response.data.data[0].dose
                        this.shelflife = response.data.data[0].shelf_life
                        this.imgview = response.data.data[0].images

                        this.savedImage = response.data.data[0].images
                        // this.categoryselect = response.data.data[0].categoryName
                        this.category.map(element => {
                            element.name,
                                element.id
                            if (element.id == response.data.data[0].category_id) {
                                this.categoryselect = element.name

                            }
                        })

                        if (response.data.data[0].is_prescription == 0) {
                            this.isprescription = 'false'
                        } else if (response.data.data[0].is_prescription == 1) {
                            this.isprescription = 'true'
                        }


                    } else {
                        this.error_with_icon(response.message)
                    }
                })
                    .catch((error) => {
                        this.error_with_icon(error)
                    })
                    .finally(() => {
                        this.show = false;
                    })
            }

        },
    },
    mounted() {
        this.getcategory();
        this.imageLink = API_URL + "/images/";
        document.title = 'Product';
        this.imageChoose = '5d47af80-fa71-48a8-9205-a6ede5938259.png'
    }
}
</script>

<style>
.loader-wrapper.loderhide {
    display: none;
}

.loader-wrapper {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    background-color: #fff;
    z-index: 11;
    top: 0;
    right: 7px !important;
}
</style>
