//Login Page Api Routes
export const LOGIN = "/api/user/login";

//View Profile Api Routes
export const VIEW_PROFILE = "/viewprofile";

//Vendor Api Routes
export const VENDOR_PAGE = "/api/vendor/registration";
export const GET_COUNTRY ="/api/user/country";
export const GET_STATE ="/api/user/state";
export const GET_CITY ="/api/user/city";
export const GET_VENDOR_LIST = "/api/vendor/vendorPendingList";
export const GET_VENDOR_APPROVAL = "/api/admin/vendorApproval";
export const GET_VENDOR_LISTAPPROVED = "/api/vendor/listOfVendor";

//Forget Password Api Routes
export const VERIFY_EMAIL = "/api/verifyEmail";
export const VERIFY_OTP = "/api/verifyOtp";
export const VERIFY_PASSWORD = "/api/updatePassword";

//RegistrationPage Api Routes
export const REGISTRATION_PAGE = "api/user/registration";

//DashboardCard Api routes
export const DASHBOARD_CARD = "/api/admin/dashBoard/countOfData";
export const ACTIVE_CUSTOMER = "/api/admin/dashBoard/graphOfCustomer";
export const LATEST_ORDER ="/api/admin/dashBoard/listOfOrder";
export const RECENTLY_JOINED ="/api/admin/dashBoard/recentlyRegistration";

//CategoryList Api Routes
export const CATEGORY_LIST = "/api/category/listOfCategory";
export const ADD_CATEGORY ="/api/category/addCategory";
export const UPDATE_CATEGORY = "/api/category/editCategory";
export const DELETE_CATEGORY = "/api/category/deleteCategory";

//Product Api Routes
export const CATEGORY_VIEW = ""
export const ADD_PRODUCT = "/api/product/addProduct";
export const PRODUCT_LIST ="/api/product/listOfProducts";
export const PRODUCT_LIST_EDIT ="/api/product/viewProduct";
export const EDIT_PRODUCT = "/api/product/editProduct";
export const DELETE_PRODUCT = "/api/product/deleteProduct";
export const PRODUCT_IMAGE = "/api/vendor/productImage";
// export const VIEW_IMAGE = "/api/product/viewImage";
export const SEARCH_PRODUCT = "/api/product/searchProduct";

//Profile Api Routes
export const PROFILE_LIST = "/api/vendor/viewProfile";
export const UPDATE_PROFILE = "/api/vendor/editProfile";

//Banner Api Routes
export const GET_BANNER = "/api/banner/listOfBanner";
export const ADD_BANNER = "/api/banner/addBanner";
export const EDIT_BANNER = "/api/banner/editBanner";
export const DELETE_BANNER = "/api/banner/deleteBanner";

//Order Api Routes
export const ORDER_LIST = "/api/vendor/orderList";
export const INVOICE_DATA = "/api/vendor/orderInvoice";

//Reset Password Api Routes
export const UPDATE_PASSWORD = "/api/vendor/resetPassword";

// Report APi Routes
export const PURCHASED_PRODUCT_REPORT = "/api/admin/purchasedProducts";
export const VENDOR_REPORT = "/api/admin/vendorReport";
export const CUSTOMER_TRANSACTION_REPORT = "/api/admin/customerTransactionReport";
export const CUSTOMER_ORDER_REPORT = "/api/admin/customerOrderReport";
export const CUSTOMER_ACTIVITY_REPORT = "/api/admin/customerReport";
export const SALES_REPORT = '/api/admin/salesReport';

//Prescription Api 
export const VIEW_APPROVAL_LIST = "api/admin/listOfApprovals"
export const DETAILSOF_PRESCRIPTION = "/api/admin/detailsOfPrescription"
export const PRESCRIPTION_APPROVAL = "api/admin/prescriptionApproval"