import Vue from 'vue';
import Vuex from 'vuex';
// import 'es6-promise/auto';
import layout from './modules/layout';
import menu from './modules/menu';
import { authentication } from './modules/authentication';
import { users } from './modules/users';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
    },
    mutations: {
      changeLang (state, payload) {
        localStorage.setItem('currentLanguage', payload.id);
        localStorage.setItem('currentLanguageIcon', payload.icon);
        window.location.reload();
      }
    },
    actions: {
      setLang ({ commit }, payload) {
        commit('changeLang', payload);
      }
    },
    modules: {
      authentication,
      users,
      layout,
      menu,    }
});

