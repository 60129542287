<template>
  <div class="header-wrapper row m-0">
    <!-- <SearchBar /> -->
    <Logo />
    <div class="left-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu">
      </ul>
    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
        <li class="language-nav">
        </li>
        <li>
          <Mode />
        </li>
        <li class="maximize">
          <Maximize />
        </li>
        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
  import Mode from './mode';
  import Maximize from './maximize';
  import Profile from './profile';
  import Logo from './logo';

  export default {
    name: 'Search',
    components: {
      Mode,
      Maximize,
      Profile,
      Logo,
    }
  };
</script>
