<template>
<div>
    <b-form-input v-model="inputvalue"  placeholder="Enter the CategoryName"></b-form-input>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: "",
        },
        type: {
      type: String,
      default: "text",
      validator: function (value) {
        // The value must match one of these strings
        return ["text", "password", "number", "file", "email"].indexOf(value) !== -1;
      },
    },
    },
    computed: {
        inputValue: {
            get: function () {
                console.log(this.value, 'this.value');
                return this.value;
            },
            // setter
            set: function (newValue) {
                console.log(newValue, 'newvalue');
                this.$emit("input", newValue);
            },
        },
    },
}
</script>

<style>

</style>
