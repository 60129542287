import apiService from "../services/ApiService.js";

import {
  ADD_PRODUCT,
  PRODUCT_LIST,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  PRODUCT_IMAGE,
  PRODUCT_LIST_EDIT,
  // VIEW_IMAGE,
  SEARCH_PRODUCT
} from "@/api/ApiRoutes";

export const addProduct = async (data) => {
  const response = await apiService.post(ADD_PRODUCT, data);
  return response;
};

export const productList = async (data) => {
  const response = await apiService.post(PRODUCT_LIST, data);
  return response;
};

export const productListEdit = async (product_id, data) => {
  const response = await apiService.get(`${PRODUCT_LIST_EDIT}/${product_id}`, data);
  return response;
};

export const productUpdate = async (id, data) => {
  const response = await apiService.put(`${EDIT_PRODUCT}/${id}`, data);
  return response;
};

export const Deleteproduct = async (id, data) => {
  const response = await apiService.put(`${DELETE_PRODUCT}/${id}`, data);
  return response.data;
};

export const ProductImage = async (formData) => {
  let response = await apiService.post(PRODUCT_IMAGE, formData, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
  return response;
};

// export const ViewImage = async (imageviewID) => {
//   const response = await apiService.get(`${VIEW_IMAGE}/${imageviewID}`);
//   return response.data;
// };
export const searchProductList = async (data) => {
  const response = await apiService.post(SEARCH_PRODUCT, data);
  return response;
};