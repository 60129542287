/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import router from './router';
import Breadcrumbs from './components/bread_crumbs';
import vuelidate from 'vuelidate';
import { store } from './store';
import firebase from 'firebase/app';
import Vue2Filters from 'vue2-filters';
import VueSweetalert2 from 'vue-sweetalert2';
import VueFormWizard from 'vue-form-wizard';
import VueTour from 'vue-tour';
import Notifications from 'vue-notification';
import { Vue2Dragula } from 'vue2-dragula';
import Toasted from 'vue-toasted';
import SmartTable from 'vuejs-smart-table';
import * as VueGoogleMaps from 'vue2-google-maps';
import { VueMasonryPlugin } from 'vue-masonry';
import VueFeather from 'vue-feather';
import VueApexCharts from 'vue-apexcharts';
import FunctionalCalendar from 'vue-functional-calendar';
import vueKanban from 'vue-kanban';
import { setupInterceptor } from "@/services/ApiService";
import VDatePicker from 'v-calendar';
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
// import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';


Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// localize('en', en);


// base coponent

const requireComponent = require.context(
  // The relative path of the components folder
  // "./components/base",
  "./components",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/

);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});


  // endss


  Vue.use(VDatePicker, {
    componentPrefix: 'vc',
  });


  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);

  import PxCard from './components/Pxcard.vue';
  Vue.component(PxCard.name, PxCard);

  import { Icon } from 'leaflet';
  delete Icon.Default.prototype._getIconUrl;

  // Multi Language Add
  import en from './locales/en.json';
  // import { defaultLocale, localeOptions } from './constants/config';
  import VueI18n from 'vue-i18n';

  // Import Theme scss
  import './assets/scss/app.scss';
  // import 'v-calendar/style.css';
  // config for firebase
  import config from './config.json';


  Icon.Default.mergeOptions({
    iconRetinaUrl: require('./assets/images/vue2leaflet/marker-icon.png'),
    iconUrl: require('./assets/images/vue2leaflet/marker-icon.png')
  });

  Vue.use(VueFeather);

  Vue.use(Toasted, {
    iconPack: 'fontawesome'
  });
  Vue.use(Vue2Dragula);

  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCdXpLSJ3Ibdu-Phs9QOvpqb9d1DtPf7wQ',
      libraries: 'places',
    },
  });

  Vue.use(Notifications);
  Vue.use(Vue2Filters);
  Vue.use(VueSweetalert2);
  Vue.use(VueFormWizard);
  Vue.use(vuelidate);
  Vue.use(VueTour);
  Vue.use(BootstrapVue);
  Vue.use(SmartTable);
  Vue.use(require('vue-chartist'));
  Vue.use(require('vue-moment'));
  Vue.component('Breadcrumbs', Breadcrumbs);
  Vue.use(VueMasonryPlugin);
  Vue.component('apexchart', VueApexCharts);
  Vue.use(FunctionalCalendar, {
    dayNames: ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  });
  setupInterceptor();
  Vue.use(vueKanban);
  Vue.use(VueI18n);




  // setup fake backend
  // import { configureFakeBackend } from './_helpers';
  // configureFakeBackend();

  const messages = { en: en };
  // const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;
  const i18n = new VueI18n({
    // locale: locale,
    fallbackLocale: 'en',
    messages,
    silentTranslationWarn: true
  });

  Vue.config.productionTip = false;
  firebase.initializeApp(config.firebase);

  // export const db = firebase.firestore();

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app');