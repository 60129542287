<template>
    <div>
        <!-- <tab-content title="Image" :before-change="validateAsync2"> -->
        <div class="setup-content" id="step-3">
            <div class="col-xs-12">
                <label class="col-form-label" for="">Image:</label>

                <b-form-file accept=".jpg, .png, .mp4 " @change="onUpload" v-model="$v.image.$model" multiple
                    placeholder="Choose the Product Image..." drop-placeholder="Drop file here..."></b-form-file>
                <div class="validation-div mb-2 text-danger"
                    v-if="typeof $v.image.required !== 'undefined' && $v.image.required == '' && $v.image.$error">
                    Image is required
                </div>
                <div class=" btn btn-secondary mb-0 pt-2 mt-2 ml-2 w-25 position-relative" @click="ImageUpload()">
                    Upload
                </div>


            </div>
        </div>

        <!-- </tab-content> -->

        <div class="loader-wrapper" :class="{ 'loderhide': !show }">
            <div class="loader-index">
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
import {
    ProductImage
} from "@/api/ProductApi";
import {
    required
} from "vuelidate/lib/validators";
import {
    API_URL
} from "../../services/ApiService";
export default {
    data() {
        return {

            image: null,
            form: {},
            files: '',
            formData: '',
            maxSize: 5 * 1024 * 1024,
            imageFiles: [],
            savedImage: null,
            show: false,
            imageChoose: ''
        }
    },
    validations() {
        return {
            image: {
                required
            },
            // imageChoose: {
            //     required
            // },
        }
    },
    methods: {
        error_with_icon(message) {
            this.$toasted.show(message, {
                theme: 'outline',
                position: 'top-right',
                type: 'error',
                icon: 'times',
                duration: 2000
            });
        },
        onUpload(event) {
            this.files = event.target.files;
            this.formData = new FormData();

            for (const element of this.files) {
                let selectedFile = element;
                if (selectedFile) {
                    const fileSize = selectedFile.size;

                    if (fileSize < this.maxSize) {

                        this.formData.append("image", element);
                    }
                    else {
                        this.error_with_icon(`${selectedFile.name} File size exceeds 5 MB.`);
                    }
                    this.imageFiles.push(selectedFile);

                }
            }
        },
        ImageUpload() {
            this.$v.$touch()
            if (this.$v.$invalid) {
            } else {
                this.show = true;

                ProductImage(this.formData).then((response) => {
                    if (response.data.status == ResponseStatusEnum.SUCCESS) {
                        this.savedImage = response.data.data
                        this.$emit('addProductbtn', this.savedImage, false)

                    } else {
                        this.error_with_icon('Product is not added')
                    }
                })
                    .finally(() => {
                        this.show = false;
                    })
            }
        },
    },
    props: {
        // image: Array,
    }, mounted() {
        this.imageLink = API_URL + "/images/";
    }

}
</script>
